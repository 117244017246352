@import "assets/css/_variables.scss";

.content_box {
    flex: 1;
    min-height: calc(100vh - 70px);
    padding: 20px;

    .nav_h5 {
        display: none;
    }
    .notice_nav_list {
        margin-bottom: 20px;

        .notice_nav_likeme {
            background: #FFF4F4;
        }
        .notice_nav_comment {
            background: #E7F0FF;
        }
        .notice_nav_activity {
            background: #FFFCF3;
        }
    }
    .notice_nav_h5 {
        cursor: pointer;
        padding: 6px 20px 6px 12px;
        background: #fff;
        border-radius: 44px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
    .activity_nav {
        border: 1px solid #AB54D5;
        box-shadow: 0px 4px 8px rgba(143, 0, 255, 0.18);
    }
    .red_dot {
        position: absolute;
        bottom: 0;
        right: 0;
        display: inline-block;
        width: 14px;
        height: 14px;
        border: 4px solid #fff;
        border-radius: 50%;
        background-color: #E84444;
    }
}

.empty {
  width: 100%;
  text-align: center;
  padding: 60px 0 10px;
  background-color: #fff;
  border-radius: 10px;
}
.notice_list {
    .notice_item {
        position: relative;
        padding: 16px;
        margin-bottom: 8px;
        background: #fff;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        .item_time {
            position: absolute;
            top: 6px;
            right: 10px;
            text-align: right;
            font-size: 12px;
            color: #9a9a9a;
        }

        .item_main {
            display: flex;
            align-items: center;
            padding: 6px 0;
            font-size: 14px;
        }
        .pl10_pc {
            padding-left: 10px;
        }

        .item_name {
            padding-left: 0px;
            font-weight: 600;
            white-space:nowrap;
        }
        .sale_type {
            color: #D2A500;
            font-size: 12px;
        }
        .item_info {
            padding-left: 10px;
        }
    }
}

.commonList {
    .common_item {
        position: relative;
        padding: 20px 20px 16px;
        margin-bottom: 8px;
        background: #fff;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        .item_time {
            position: absolute;
            top: 6px;
            right: 10px;
            text-align: right;
            font-size: 12px;
            color: #9a9a9a;
        }

        .common_main {
            display: flex;
            align-items: flex-start;
            padding: 6px 0;
            font-size: 14px;
        }
        .common_icon {
            margin-top: -2px;
        }
        .common_des {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }

        .common_content {
            padding-left: 8px;
        }

        .item_name {
            // padding-left: 8px;
            font-weight: 600;
        }

        .item_info {
            padding-left: 10px;
        }
        .common_content {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .common_reply {
            width: 100%;
            min-height: 70px;
            margin-top: 10px;
            // margin-left: 8px;
            padding: 10px;
            background: #F2F2F2;
            border-radius: 4px;
            position: relative;
        }

        .reply_btn {
            position: absolute;
            bottom: 4px;
            right: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 20px;
            background: #FFFFFF;
            border-radius: 3px;
            text-align: right;
        }
        .reply_btn_h5 {
            display: none;
        } 
    }
}
.content_box_h5 {
    display: none;
}

@media #{$smWidth} {
    .content_box {
        // display: none;
        padding: 0;
        background-color: #fff;
        
        .notice_nav_list {
            justify-content: center;
            margin: 10px 0 6px 0;
        }
        .nav_h5 {
            display: flex;
            justify-content: space-between;
            background: #fff;
            padding: 8px 20px;
            font-size: 18px;
            border-bottom: 1px solid #dedede;
        }
        
        .notice_nav_h5 {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 4px 10px 4px 6px;
            // box-shadow: none;

            img {
                width: 20px;
            }
        }
    }
    // .content_box_h5 {
    //     display: block;
    //     width: 100%;
    // }
   
    .notice_title {
        display: none;
    }
    
    .red_dot {
        position: absolute;
        bottom: 0;
        right: 0;
        display: inline-block;
        width: 14px;
        height: 14px;
        border: 4px solid #fff;
        border-radius: 50%;
        background-color: #E84444;
    }
    .line_right {
        border-right: 1px solid #dedede;
    }
    .line_hr {
        margin-top: 10px;
        height: 1px;
        border: none;
        background-color: #dedede;
    }
    .notice_list {

        :global {
            .infinite-scroll-component {
                padding-bottom: 10px;
            }
        }
        .notice_icon {
            display: none;
        }
        .notice_item {
            width: 90%;
            margin: 8px auto;
            padding: 16px 12px;
            
            // .item_time {
            //     left: 12px;
            // }
            .pl10_pc {
                padding-left: 0px;
            }
            .item_name {
                padding-left: 0px;
            }
    
            .item_info {
                padding-left: 6px;
            }
        }
    }
    .commonList {
        .common_item {
            width: 90%;
            margin: 8px auto;
            padding: 16px 12px;
            
            // .item_time {
            //     left: 12px;
            // }
            .common_main {
                display: flex;
                align-items: flex-start;
                padding: 6px 0;
                font-size: 14px;
            }
            .common_des {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
            .common_content {
                padding-left: 0px;
            }
    
            .common_content {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 10;
                overflow: hidden;
            }
            .reply_btn {
                display: none;
            }
            .reply_btn_h5 {
                display: block;
                margin-top: 4px;
                text-align: center;
                color: #000;
            }
        }
    }
}