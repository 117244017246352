@import "assets/css/_variables.scss";

.eventDetailPage {
  width: 1280px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;

  .eventBlind_baseInfo {
    display: flex;

    .box_left {
      width: 604px;
      height: 860px;
      margin-right: 20px;
      padding: 40px;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
    }
    .upload_cover {
      width: 524px;
      height: 524px;
      border: 1px solid #DEDEDE;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .bg_box {
      padding: 24px 30px;
      background: #F3F0F9;
      border-radius: 4px;
    }
    .box_right {
      width: 656px;
      height: 860px;
      padding: 40px;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      border-radius: 10px;
      box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
      overflow: auto;
    }
    .task_item {
      height: 60px;
      margin-bottom: 10px;
      padding: 0 30px;
      line-height: 60px;
      border: 1px solid #E0CDEB;
      border-radius: 6px;
    }

  }
  .baseInfo_name {
    font-weight: 600;
    font-size: 34px;
  }
  .baseInfo_desc {
    width: 860px;
    font-size: 14px;
    line-height: 20px;
  }
  .info_list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .w250 {
      width: 250px;
    }
    .w170 {
      width: 170px;
    }
  }
  .info_item {
    // margin-right: 10px;
    padding: 10px 30px;
    background: #F7F3FD;
    border-radius: 4px;
  }
  .func_box {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .manage_disable {
    opacity: 0.5;
  }
  .publish_btn {
    width: 200px;
    height: 50px;
    background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
    color: #fff;
    font-size: 16px;
  }
  .eventManage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    height: 50px;
    padding: 0 20px;
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
  }
  .badgeManage {
    margin-top: 20px;
    padding: 30px 40px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
  }
  .badgeList {
    display: flex;
    margin-top: 20px;

    .badge_item {
      margin-right: 12px;
    }
    .badge_box {
      position: relative;
      width: 220px;
      height: 220px;
      border: 1px solid #DEDEDE;
      border-radius: 6px;
      overflow: hidden;
    }
    .badge_box:hover{
      .badge_func {
        display: flex;
      }
    }
    .badge_img {
      width: 100%;
    }
    .badge_func {
      position: absolute;
      top: 20px;
      left: 19px;
      display: none;
      align-items: center;
      width: 180px;
      height: 40px;
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid #FFFFFF;
      border-radius: 20px;
      .download {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .line_left {
      border-left: 1px solid #DEDEDE;
    }
    .add_box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 225px;
      height: 225px;
      font-size: 48px;
      color: #DEDEDE;
      background: #FFFFFF;
      border: 1px solid #DEDEDE;
      border-radius: 6px;
    }
  }
}

@media #{$smWidth} {

}
