@import "assets/css/_variables.scss";

.draft_card {
  display: inline-block;
  border-radius: 4px;
  margin-right: 25px;
  margin-top: 12px;
  position: relative;
  width: 160px;
  height: 160px;
  overflow: hidden;

  .cover {
    width: 100%;
  }
  .draft_func {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
  }
  .draft_num {
    position: absolute;
    color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &:hover {
    .draft_func {
      display: flex
    }
    .draft_num {
      display: none;
    }
  }
}

.draft_publish {
  width: 1px;
  height: 54px;
  background-color: #fff;
}
