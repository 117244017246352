@import "assets/css/_variables.scss";
// .follow_btn {
//   width: 100px;
// }
.follow_btn {
  font-size: 12px;
  width: 80px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  border: 1px solid #000;
  border-radius: 6px;
  cursor: pointer;
}
.followed {
  color: #845DA4;
  border: 1px solid #845DA4;
}