@import "./variables";
.ant-btn:hover, .ant-btn:focus {
  color: #6F4293;
  border-color: #6F4293;
}
.ant-btn {
  border-radius: 6px;
  font-size: 14px;
  height: 36px;
  &-primary {
    background: #000;
    border: none;
    color: #fff;
    border-radius: 6px;
    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background: #000;
      outline: none;
    }
  }
}

.ant-btn[disabled] {
  background: #bbb2b9;
  color: #fff;

  &:hover,
  &:focus,
  &:active {
    background: #9a9a9a;
    color: #fff;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f9f9f9;
}
.ant-spin-dot-item {
  background-color: #000;
}

.ant-message-notice {
  .ant-message-notice-content {
    padding: 0;
    background: transparent;
    border-radius: 6px;
  }

  .ant-message-success,
  .ant-message-error {
    padding: 10px 16px;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
  }

  .anticon {
    display: none;
  }

  .ant-message-success {
    background: #000;
  }

  .ant-message-error {
    background: #ff5959;
  }
}

.ant-modal {
  &-content {
    border-radius: 6px;
  }

  &-header {
    border-bottom: $hr;
    border-radius: 6px 6px 0 0;
    text-align: center;
  }

  &-title {
    font-size: 18px;
    font-weight: 600;
  }
}

.ant-input, .ant-input-affix-wrapper {
  border-radius: 6px;
  background-color: #f9f9f9;
}
.ant-input:hover, .ant-input-affix-wrapper:hover {
  border-color: #000;
  background: #fff;
}
.ant-input:focus, .ant-input-focused, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused  {
  border-color: #000;
  box-shadow: none;
  background: #fff;
}

.ant-switch{
  &-checked {
    background: linear-gradient(226.11deg, #5A50D0 0%, #5D4ADD 11.46%, #9F43D7 41.46%, #CF5A7C 66.48%, #EC811E 83.97%, #FFA858 97.92%);
  }
  
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
  border: 1px solid #EBE3FB;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,.ant-select:not(.ant-select-customize-input)  .ant-select-selector:hover {
  background: #fff;
  border: 1px solid #6F4293;
  box-shadow: none;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #000;
  box-shadow: none;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #000;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #000;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #f4f4f4;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #DFCEFA;
}
.ant-skeleton-element .ant-skeleton-image {
  background: #F0E7FE;
}
.ant-tabs-tab:hover {
  color: #6F4293;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: $linkColor;
}
.ant-pagination-prev:focus-visible:not(.ant-pagination-disabled) .ant-pagination-item-link,
.ant-pagination-next:focus-visible:not(.ant-pagination-disabled) .ant-pagination-item-link,
.ant-pagination-prev:hover:not(.ant-pagination-disabled) .ant-pagination-item-link,
.ant-pagination-next:hover:not(.ant-pagination-disabled) .ant-pagination-item-link {
  color: $linkColor;
}
