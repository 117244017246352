@import "assets/css/_variables.scss";

.black_table {
    :global {
        .ant-table table {
            border-radius: 4px;
            overflow: hidden;
        }
        .ant-table-thead>tr>th {
            //   line-height: 30px !important;
            height: 30px;
            padding: 0 10px;
            background-color: #EBE3FB !important;
            color: #6F4293 !important;
            // background-color: #000 !important;
            // color: #fff !important;
            font-weight: 600;
            font-size: 12px;

            &::before {
                display: none;
            }
        }

        .ant-table-tbody>tr>td {
            padding: 0px 5px;
            height: 60px;
            line-height: 28px !important;
            font-size: 16px;
        }

        .rowBgWhite {
            background: #fff !important;
        }

        .rowBgGray {
            background: #f9f9f9 !important;
        }
    }
}