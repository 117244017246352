@import "assets/css/_variables.scss";

.select_project {
  width: 360px;
}
.select_item {
  flex: 1;
  margin-left: 30px;
}
.option_img {
  display: inline-block;
  height: 20px;
}

.option_name {
  font-weight: 600;
  font-size: 18px;
  margin: 0 20px;
}

.option_id {
  font-size: 14px;
}

.card {
  margin: 24px 0;
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid #dedede;
  flex-wrap: wrap;
}

.img_box,
.empty {
  width: 360px;
  height: 360px;
  border: 1px solid #dedede;
}
.img_box {
  background: center / contain no-repeat;
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info_box {
  flex: 1;
  margin-left: 30px;
}
.fl {
  display: flex;
  flex-wrap: wrap;
}
.fl_item {
  width: 33.33%;
}
.fl_title {
  font-size: 14px;
}
.fl_value {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  word-break: break-all;
}
.fl_address {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  word-break: break-all;
}
.check {
  background: #f4f4f4;
  border-radius: 0 0 6px 6px;
  padding: 20px;
}
.btn_box {
  text-align: right;
  margin-top: 30px;
}
.history_box {
  margin-top: 30px;
  font-size: 14px;
}
.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.history_address {
  font-size: 16px;
  font-weight: 600;
  word-break: break-all;
}

.table_box {
  margin-top: 30px;
  :global {
    .ant-table {
      border: 1px solid #dedede;
    }
    .ant-table-thead > tr > th {
      background-color: #000 !important;
      color: #fff !important;
      font-weight: 600;
    }
  }
}
.history_redeem {
  display: flex;
  text-align: left;
  align-items: center;
}
.history_img {
  width: 60px;
  height: 60px;
  background: center / contain no-repeat;
  border: 1px solid #dedede;
}
.history_info {
  margin-left: 20px;
}
.history_info_title {
  font-size: 16px;
  font-weight: 600;
}

@media #{$smWidth} {
  .select_project {
    width: 100%;
    margin-bottom: 20px;
  }
  .select_item {
    width: 100%;
    margin-left: 0px;
  }
  .img_box, .empty {
    display: none;
  }
  .info_box {
    margin: 0;
  }
  .fl_item {
    width: 50%;
  }
}
