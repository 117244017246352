@import "assets/css/_variables.scss";
$rightWidthL: 300px;
$rightWidthS: 260px;

.publish_icon_h5 {
  display: none;
}
.publish_pc {
  display: block;
}
.content_box {
  flex: 1;
  // background-color: #f6f6f6;
  min-height: calc(100vh - 70px);
  padding: 10px 20px;
}
.nav_h5 {
  display: none;
}
.tab_box {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab_btn {
  text-align: center;
  width: 120px;
  height: 36px;
  line-height: 36px;
  color: #9a9a9a;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 20px;
}
.active {
  color: #fff;
  background-color: #000;
}
.activity_box {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}
.b_l {
  border-right: 1px solid #E0CDEB;
}

@media #{$smWidth} {
  .publish_icon_h5 {
    display: block;
    position: fixed;
    bottom: 90px;
    right: 24px;
    z-index: 9;
  }
  .publish_pc {
    display: none;
  }
  .content_box {
    padding: 0;
  }
  .tab_box {
    padding: 0 15px;
  }
  .tab_btn {
    width: 72px;
    margin-right: 16px;
  }
  .nav_h5 {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 8px 20px;
    font-size: 18px;
    border-bottom: 1px solid #dedede;
  }
  .tab_box {
    margin-top: 10px;
  }
}
