@import "assets/css/_variables.scss";
.back_box {
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.back_icon {
  width: 13px;
  margin-right: 16px;
}
