@import "assets/css/_variables.scss";

.list_title {
  font-size: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.page_content_collections {
    padding-bottom: 30px;
    .collection_header {
        width: 100%;
        height: 80px;
        line-height: 80px;
    }
    .header_main {
        max-width: 1280px;
        margin: auto;
    }
    .collection_search {
        width: 280px;
        height: 40px;
    }
    .collection_gaming {
        position: relative;
        max-width: 1280px;
        margin: auto;
    }
    .collection_list {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        margin-top: 20px;
        height: 550px;
        overflow: hidden;
    }
    .collection_item {
        width: 236px;
        height: 260px;
        margin-left: 7px;
        margin-right: 7px;
        margin-bottom: 16px;
        border: 1px solid #DEDEDE;
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        background-color: #fff;
        .item_cover {
            width: 100%;
            height: 148px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            overflow: hidden;
            .cover {
                width: 100%;
                // height: 100%;
                height: auto;
            }
            .chainType {
                position: absolute;
                top: 0;
                right: 0;
                width: 24px;
            }
        }
        .collection_creator {
            position: relative;
            width: 64px;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -30px;
            left: 88px;
            border: 4px solid #fff;
            border-radius: 50%;
            z-index: 2;
            .avatar {
                width: 60px;
                height: 60px;
                border: 1px solid #fff;
                border-radius: 50%;
            }

        }
        .name {
            margin-top: -20px;
            text-align: center;
            font-size: 16px;
            font-weight: 600;
        }
        .des {
            margin-bottom: 30px;
            text-align: center;
            font-size: 14px;
            padding: 0 5px;
            color: #1673e1;
        }
    }
    .collection_item:hover {
        .item_cover {
            height: 70px;
        }
        .des {
            display: none;
        }
        .collection_des {
            width: 100%;
            padding: 0 10px 10px 10px;
            background: #fff;
            height: 112px;
            font-size: 12px;
            line-height: 1.3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            overflow: hidden;
            text-align: center;
        }
    }
    
    .viewPack {
        position: relative;
        margin-top: -10px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        text-align: center;
    }
    .packUp {
        position: absolute;
        bottom: 50px;
        right: -130px;
    }
    .tranlate90 {
        svg {
            transform: rotate(-90deg);
        }
    }

    .collection_main {
        max-width: 1280px;
        margin: auto;
    }
    .main_list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        overflow: hidden;
    }
}

@media #{$smWidth} {
    .page_content_collections {
        
        .collection_header {
         padding: 0 10px;   
        }
        .collection_search {
            width: 180px;
            height: 36px;
        }
        .collection_list,.main_list {
            padding: 0 20px;
        }
        .collection_item {
            width: 150px;
            height: 165px;
            margin-left: 8px;
            margin-right: 8px;

            .item_cover {
                width: 100%;
                height: 94px;
            }
            .collection_creator {
                position: relative;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                top: -20px;
                left: 54px;
                border: 4px solid #fff;
                border-radius: 50%;
                z-index: 2;
                .avatar {
                    width: 38px;
                    height: 38px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                }
    
            }
        }
    }
}