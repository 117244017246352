@import "assets/css/_variables.scss";

.content_box {
  max-width: 1280px;
  margin: 0 auto;
}
.top_box {
  padding-top: 80px;
  margin-bottom: 20px;
  margin-top: -60px;
}
.box_bg {
  background-color: #fff;
}
.no_bg {
  background-color: transparent;
}
.search_box {
  color: #000;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.top_title {
  flex: auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.tab {
  cursor: pointer;
  color: #876D9B;
}
.tab_active {
  color: inherit;
}
.fence {
  width: 2px;
  height: 30px;
  background: #E0CDEB;
  margin: 0 30px;
}
.select {
  width: 160px;
  :global {
    // .ant-select-selector {
    //   background-color: rgba(0, 0, 0, 0.3) !important;
    // }
    // .ant-select-arrow {
    //   color: #fff;
    // }
    .ant-select-selector {
      font-size: 14px;
    }
  }
}
.select_mg {
  margin: 0 10px 0 20px;
}
.select_layout {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  padding: 2px;
  background: #FFFFFF;
  border: 1px solid #E0CDEB;
  border-radius: 6px;

  .layout {
    width: 26px;
    height: 26px;
    text-align: center;
    cursor: pointer;
  }
  .layout_active {
    width: 26px;
    height: 26px;
    text-align: center;
    background: #6F4293;
    border-radius: 4px;
  }
}
.list_box {
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;
}
.loading_skeleton_nft {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 276px;
  border-radius: 4px;
  padding: 2px;
  margin: 8px;
  cursor: pointer;
  box-shadow: 0 0 0 1px #dedede;
  background: #fff;

  .chainType {
    width: 100%;
    height: 24px;
    margin-bottom: 5px;
    color: #fff;
  }
  .loading_img {
    position: relative;
    width: 180px;
    padding-bottom: 180px;
  }

  :global {
    .ant-skeleton-element {
      position: absolute;
      top: 0;
      width: 100%;
    }
    .ant-skeleton-image {
      width: 180px;
      height: 180px;
      margin: auto;
    }
    .ant-skeleton-title {
      margin-top: 10px;
    }
    .ant-skeleton-content {
      padding-left: 8px;
    }
    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin-top: 0;
    }
  }
}
.card_module {
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
}
.layout_list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.layout_list_header {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  padding: 6px 50px;
  border-radius: 8px;
  background: #E0CDEB;
  color: #6F4293;
}
.marketplace_no_data {
  height: 480px;
}
.loading_skeleton {
  :global {
    .ant-skeleton-image {
      width: 300px;
      height: 300px;
      margin-right: 20px;
      margin-bottom: 40px;
      border-radius: 4px;
    }
  }
}
.time {
  font-size: 12px;
  // font-weight: 600;
}

.logo {
  margin-top: 20px;
  // margin-left: 15px;
  .logo_img {
    width: 58px;
  }
}
.card_title {
  height: 40px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
}
.badge_des {
  margin-top: 60px;
  font-size: 14px;
  .img {
    margin-top: -10px;
    margin-left: 10px;
    width: 480px;
  }
}
@media #{$smWidth} {
  .content_box {
    width: 100%;
    padding: 10px;
  }
  .top_box {
    background: url('../../assets/img/badge/top_bg.jpg') no-repeat;
    background-size: cover;
    padding-top: 60px;
    margin-bottom: 0;
    background-position: 36%;
  }
  .box_bg {
    background-color: #000;
  }
  .search_box {
    color: #fff;
  }
  .tab {
    font-size: 24px;
  }
  .fence {
    width: 1px;
    height: 22px;
    margin: 0 10px;
  }
  .switch {
    box-shadow: 0px 0px 1px 1px #845da4;
  }
  .select_layout {
    display: none;
  }
  .select_mg {
    margin: 0 20px 0 0;
  }
  .select {
    margin-top: 15px;
    color: #fff;
    :global {
      .ant-select-selector {
        background-color: rgba(0, 0, 0, 0.3) !important;
      }
      .ant-select-arrow {
        color: #fff;
      }
    }
  }
  .select_nft {
    margin-top: 0;
    margin-bottom: 7px;
  }
  .card_module {
    margin: 0;
  }
  .marketplace_no_data {
    height: 380px;
  }
  .loading_skeleton {
    :global {
      .ant-skeleton-image {
        width: 160px;
        height: 160px;
        margin-left: 3px;
        margin-right: 3px;
        margin-bottom: 10px;
        border-radius: 10px;
      }
    }
  }
  .logo {
    margin-right: 10px;
    .logo_img {
      width: 90px;
    }
  }
  .card_title {
    height: 60px;
    font-size: 24px;
  }
  .radio:global(.ant-radio-group) {
    :global(.ant-radio-button-wrapper) {
      padding: 6px 20px;
      margin-right: 15px;
    }
  } 
  .badge_des {
    .img {
      width: 80%;
    }
  } 
}
