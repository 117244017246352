@import "assets/css/_variables.scss";

.relation2D {
    .w320 {
        width: 320px;
    }
}
.graph_search {
    width: 80%;
    :global {
        .ant-btn {
            height: 32px;
        }
    }
}

@media #{$smWidth} {

}