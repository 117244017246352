@import "assets/css/_variables.scss";

.share_div {
  position: absolute;
  z-index: 1000;
  width: 1140px;
  left: 0;
  top: 0;
  background-color: #F5EFFF;
  padding: 0 20px;
  border-radius: 10px;
}
.top_box {
  max-width: 1100px;
  margin: 25px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.info_box, .link_box, .board {
  background: rgba(255, 255, 255, 1);
  border: 2px solid #FFFFFF;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
  border-radius: 10px;
}
.info_box {
  position: relative;
  width: 630px;
  height: 270px;
}
.link_box {
  width: 445px;
  height: 270px;
  overflow: hidden;
  position: relative;
}
.right_top_box {
  position: absolute;
  top: 14px;
  right: 14px;
}
.pc_hidden {
  display: none;
}
.info_top {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 35px 0;
  padding: 0 15px 30px;
  border-bottom: 1px solid #D9CEEE;
}
.info_header_box, .info_header_box_l {
  width: 120px;
  height: 120px;
  position: relative;
  margin-right: 30px;
  border-radius: 50%;
  flex: none;
  padding: 2px;
}
.info_header_box {
  background: #000;
}
.info_header_box_l {
  background-image: linear-gradient(226.11deg, #5A50D0 0%, #5D4ADD 11.46%, #9F43D7 41.46%, #CF5A7C 66.48%, #EC811E 83.97%, #FFA858 97.92%);
}
.info_header {
  background-size: cover;
  background-position: center;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.nft_tag {
  position: absolute;
  width: 60px;
  left: 30px;
  bottom: 0;
}
.name_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.info_name {
  font-size: 26px;
  font-weight: 600;
  margin-right: 15px;
  max-width: 230px;
}
.info_handle {
  font-size: 14px;
  font-weight: 300;
  max-width: 100px;
  color: #845DA4;
  margin-top: 8px;
}
.info_addr {
  width: 220px;
  height: 30px;
  margin: 10px 0 16px;
  padding: 0 8px 0 16px;
  border-radius: 15px;
  cursor: pointer;
  background: #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #D4D4D4;
  font-size: 14px;
}
.num {
  font-weight: 600;
  margin-left: 6px;
}
.column {
  border-right: 1px solid #DEDEDE;
  height: 21px;
  margin: 0 19px;
}
.info_bottom {
  font-size: 14px;
  margin: 20px 35px 0 44px;
}
.web_box {
  padding: 20px 30px;
  height: 100%;
  :global {
    .df_align_center {
      margin-bottom: 11px;
    }
  }
}
.web_img {
  margin-right: 16px;
  flex: none;
}
.img_gray {
  filter: grayscale(100%);
}
.add_text {
  color: #845DA4;
}
.share_box {
  background: linear-gradient(96.74deg, #5A50D0 4.86%, #9F43D7 29.16%, #CF5A7C 52.5%, #EC811E 71.56%, #FFA858 96.33%);
  width: 100px;
  height: 40px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-top-left-radius: 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.board {
  max-width: 1100px;
  margin: 25px auto;
}
.tab_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}
.tab_btn {
  cursor: pointer;
  height: 100%;
  margin: 0 60px;
  display: flex;
  align-items: center;
  text-align: center;
}
.tab_active {
  font-weight: 600;
  border-bottom: 4px solid #845DA4;
}
.wow_box {
  max-width: 650px;
  margin: 0 auto;
}
.nft_tab_box {
  max-width: 848px;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tab_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.type_btn_h5 {
  font-size: 14px;
  cursor: pointer;
}
.type_active {
  font-weight: 600;
}
.fence {
  width: 1px;
  height: 18px;
  background-color: #dedede;
  margin: 0 24px;
}
.select_w180 {
  width: 180px;
}
.nft_box {
  max-width: 889px;
  margin: 0 auto;
}
.view_box {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
}
.g6_box {
  overflow: hidden;
  position: relative;
  background: url(../../assets/img/graph/graph-bg.png) no-repeat;
  background-size: cover;
  border-radius: 0 0 10px 10px;
}
.view_all {
  position: absolute;
  right: 30px;
  bottom: 30px;
  font-weight: 600;
  font-size: 16px;
  padding: 11px 30px;
  border: 1px solid #845DA4;
  border-radius: 6px;
  color: #fff;
  background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
  &:hover {
    color: #fff;
  }
}
.featured_box {
  padding: 20px 28px;
  overflow: hidden;
}
.featured_item {
  width: 240px;
  height: 240px;
  border: 3px solid #FFFFFF;
  border-radius: 4px;
  position: relative;
  float: left;
  margin: 10px;
}
.nail_block {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}
.featured_content_item {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nail_box {
  text-align: center;
  color: #fff;
  font-size: 14px;
}
.list_nft_box {
  margin: 0;
  overflow: hidden;
}
.item_nft {
  float: left;
  margin: 4px;
  width: 147px;
  height: 147px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 4px;
  cursor: pointer;
}
.item_img {
  max-width: 100%;
  max-height: 100%;
}
.featured_info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,.7));
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}
.featured_name {
  max-width: 180px;
}
.card_chain {
  border-radius: 50%;
}
.featured_handle {
  position: absolute;
  top: 15px;
  left: 30px;
  right: 30px;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #FFFFFF;
  border-radius: 46px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  padding: 0 10px;
}
.featured_fence {
  border-left: 1px solid #fff;
  height: 18px;
  opacity: 0.3;
}
.featured_item {
  &:hover {
    .featured_handle, .featured_info {
      display: flex;
    }
  }
}
.featured_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
}


@media #{$smWidth} {
  .share_div {
    width: 390px;
    padding: 0;
  }
  .h5_hidden {
    display: none;
  }
  .pc_hidden {
    display: block;
    margin: 14px auto;
    width: 220px;
  }
  .top_box {
    margin: 20px 24px 24px;
  }
  .info_box {
    width: 100%;
    height: auto;
  }
  .info_top {
    justify-content: center;
    padding-bottom: 8px;
    margin: 18px 20px 0;
  }
  .info_header_box, .info_header_box_l {
    margin: 0;
  }
  .name_box {
    justify-content: center;
  }
  .info_handle {
    width: 100%;
    text-align: center;
  }
  .info_addr {
    margin: 12px auto;
  }
  .num {
    display: block;
    margin-left: 0;
    text-align: center;
  }
  .column {
    height: 44px;
  }
  .info_bottom {
    margin: 15px 20px;
  }
  .link_box {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .web_box {
    padding-bottom: 30px;
  }
  .tab_btn {
    margin: 0 10px;
  }
  .nft_tab_box {
    margin-top: 20px;
  }
  .tab_item {
    width: 100%;
    padding: 0 20px;
  }
  .select_w180 {
    width: 150px;
    margin-top: 20px;
  }
  .featured_box {
    padding: 2px 0;
    width: 370px;
    margin: 0 auto;
  }
  .featured_item {
    margin: 2px;
    width: 180px;
    height: 180px;
  }
  .featured_handle {
    top: 10px;
    left: 10px;
    right: 10px;
  }
}
