@import "assets/css/_variables.scss";
$emoji-base-color: #ffda6a;
$emoji-like-color: #548dff;
$emoji-love-color: #f55064;
$emoji-angry-color: #d5234c;
$emoji-white-color: #ffffff;
$emoji-black-color: #000000;
$emoji-text-color: #8a8a8a;

.badge_modal {
    text-align: center;
    width: 450px !important;
    :global {
        .ant-modal-header {
            border-bottom: none;
            .ant-modal-title {
                font-size: 20px;
            }
        }
        .ant-modal-body {
            padding: 0 10px 20px 10px;
        }
        .ant-modal-footer {
            border-top: none;
            text-align: center;
            padding-bottom: 25px;
        }
    }
    .emoji {
        width: 120px;
        height: 120px;
        margin: 15px 15px 40px;
        background: $emoji-base-color;
        display: inline-block;
        border-radius: 50%;
        position: relative;
      
        &:after {
          position: absolute;
          bottom: -40px;
          font-size: 18px;
          width: 60px;
          left: calc(50% - 30px);
          color: $emoji-text-color;
        }
      }
      
      .emoji__face,
      .emoji__eyebrows,
      .emoji__eyes,
      .emoji__mouth{
        position: absolute;
      
        &:before,
        &:after {
          position: absolute;
          content: "";
        }
      }
      
      .emoji__face {
        width: inherit;
        height: inherit;
      }
      
      .emoji--yay {
        &:after {
          /*content: 'Yay';*/
          animation: yay-reverse 1s linear infinite;
        }
      
        .emoji__face {
          animation: yay 1s linear infinite alternate;
        }
      
        .emoji__eyebrows {
          left: calc(50% - 3px);
          top: 30px;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background: transparent;
          box-shadow: -6px 0 0 0 $emoji-black-color, -36px 0 0 0px $emoji-black-color, 6px 0 0 0 $emoji-black-color,
            36px 0 0 0px $emoji-black-color;
      
          &:before,
          &:after {
            width: 36px;
            height: 18px;
            border-radius: 60px 60px 0 0;
            background: transparent;
            border: 6px solid black;
            box-sizing: border-box;
            border-bottom: 0;
            bottom: 3px;
            left: calc(50% - 18px);
          }
      
          &:before {
            margin-left: -21px;
          }
      
          &:after {
            margin-left: 21px;
          }
        }
      
        .emoji__mouth {
          top: 60px;
          background: transparent;
          left: 50%;
      
          &:after {
            width: 80px;
            height: 80px;
            left: calc(50% - 40px);
            top: -75px;
            border-radius: 50%;
            background: transparent;
            border: 6px solid $emoji-black-color;
            box-sizing: border-box;
            border-top-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
            z-index: 1;
          }
      
          &:before {
            width: 6px;
            height: 6px;
            background: transparent;
            border-radius: 50%;
            bottom: 5px;
            left: calc(50% - 3px);
            box-shadow: -25px 0 0 0 $emoji-black-color, 25px 0 0 0 $emoji-black-color, -35px -2px 30px 10px $emoji-angry-color,
              35px -2px 30px 10px $emoji-angry-color;
          }
        }
      }
      
      @keyframes yay {
        25% {
          transform: rotate(-15deg);
        }
        75% {
          transform: rotate(15deg);
        }
      }
      
}
.item_img {
  max-width: 100%;
  max-height: 100%;
}
.img_box, .info_box{
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}

.description_box {
  padding: 20px 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}
.info_box {
  margin-left: 20px;
  flex: 1;
}
.description_box {
  margin-top: 20px;
}
.badgeDetail {
  width: 1280px;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
    .badge_cover {
        width: 500px;
        height: 500px;
        border: 1px solid #dedede;
        overflow: hidden;
        text-align: center;
        position: relative;
        .img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }
    .btn_setWhiteList {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .chain {
        border-radius: 50%;
    }

    .time_box {
      padding: 0 20px;
      background: #F7F3FD;
      height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
    .addr_box {
      height: 70px;
      background: #F7F3FD;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px auto;
      padding: 0 20px;
      border-radius: 4px;
    }
    .info_item {
      height: 70px;
      margin-right: 10px;
      width: 100%;
      background: #F7F3FD;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:last-child {
        margin-right: 0;
      }
    }
    :global {
        .ant-table-thead > tr > th {
            padding: 10px 16px;
        }
        .ant-table-tbody > tr > td, .ant-table tfoot > tr > th {
            padding: 10px;
            border-bottom: none;
        }
    }
    .badge_series {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      .item_cover {
        // height: 250px;
        width: 100%;
        padding-bottom: 100%;
        text-align: center;
        border: 1px solid #DEDEDE;
        border-radius: 6px;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover
      }
    }
}
.series_item {
  width: 270px;
  margin-bottom: 10px;
}
.pl30_h5 {
    padding-left: 50px;
}
.mr10_h5 {
    margin-right: 10px;
}
.owerInfo {
    img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
}
:global {
  .taskList {
    .btn_pruple {
      background: #6F4293;
    }
    .btn_gradient {
      background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
      color: #fff;
      font-size: 16px;
    }
    .taskItem {
      position: relative;
      margin-bottom: 10px;
      padding: 0 20px;
      border: 1px solid #E0CDEB;
      border-radius: 6px;
    }
    .taskHeader {
      padding: 15px 0;
      cursor: pointer;
    }
    .taskAuth {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      cursor: pointer;
    }
    .content {
      display: none;
      padding-bottom: 15px;
    }
    
  }
  .collapse-task-list {
    .btn_gradient {
      background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
      color: #fff;
      font-size: 16px;
    }
    background-color: #fff;
    border: none;
    .ant-collapse-item {
      margin-bottom: 20px;
      border: 1px solid #6F4293;
      border-radius: 6px;
      overflow: hidden;
    }
    .ant-collapse-item:last-child,  .ant-collapse-item:last-child > .ant-collapse-header {
       border-radius: 6px;
    }
  }
}

@media #{$smWidth} {
  .series_item {
    width: 50%;
  }
  .img_box, .info_box, .description_box {
    padding: 20px;
  }
  .info_box {
    margin-left: 0;
    margin-top: 8px;
  }
  .description_box {
    margin-top: 8px;
  }
    .badgeDetail {
      width: calc(100% - 40px);
      margin: auto;
        .badge_cover {
            width: 100%;
            height: auto;
            margin: 0;
            .img {
                width: 100%;
            }
        }
        .pl30_h5 {
            padding-left: 0;
        }
        .mr10_h5 {
          margin-right: 0;
          margin-bottom: 10px;
        }
        .time_box, .addr_box, .info_item {
          padding: 0 8px;
          height: 62px;
        }
    }
}