@import "assets/css/_variables.scss";

.event_item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 15px 50px;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
}
.item_logo {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}
.item_chainType_logo {
  width: 24px;
  height: 24px;
  margin-left: 40px;
  border-radius: 50%;
}
.item_creator {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.btn_end {
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: #E0CDEB;
  color: #fff;
}
.btn_soon {
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
  color: #fff;
}
.btn_active {
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(95.14deg, #5A50D0 0%, #9F43D7 26.56%, #CF5A7C 52.08%, #EC811E 72.92%, #FFA858 98.44%);
  color: #fff;
}
