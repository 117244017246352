@import "assets/css/_variables.scss";

.space_between {
  display: flex;
  justify-content: space-between;
}
.card_list {
  display: flex;
  flex-wrap: wrap;

}

.df_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.des {
  li {
    list-style-type: disc;
    list-style-position: inside;
  }
  .des_content {
    background: #f4f4f4;

  }
}

@media #{$smWidth} {
  .card {
    width: 88%;
    margin: 0 auto 15px auto;
   
  }
}
.mr5 {
  margin-right: 5px;
}