@import "assets/css/_variables.scss";

.card {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 263px;
  border-radius: 6px;
  padding: 2px;
  margin: 6px;
  cursor: pointer;
  // box-shadow: 0 0 0 1px #dedede;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
  overflow: hidden;
  // &:hover {
  //   border-color: transparent;
  //   box-shadow: none;
  // }
  &:hover {
    .imgSrc {
      max-width: 120%;
      max-height: 120%;
    }
  }
}
.is_auction {
  background: $multicolour;
  box-shadow: none;
}
.card_box {
  position: relative;
  height: 100%;
  padding: 4px;
  background-color: #fff;
  border-radius: 4px;
}
.chain_type {
  display: block;
  width: 24px;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
}
.nft_img {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.nft_img_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
  .imgSrc {
    max-width: 100%;
    max-height: 100%;
    transition: all 0.5s;
  }
}
.nft_name {
  margin: 6px 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.timer_box {
  position: absolute;
  bottom: 0;
  right: -4px;
  left: -4px;
  background: $multicolour;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  font-size: 14px;
}
.price_box {
  margin: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.price_bid {
  font-size: 14px;
}
.price_last {
  color: #9a9a9a;
  font-size: 14px;
}

@media #{$smWidth} {
  .card {
    width: calc(50% - 10px);
    margin: 5px;
    height: auto;
  }
}
