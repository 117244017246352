@import "assets/css/_variables.scss";
.header_share {
  // height: 40px;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #845DA4;
  font-weight: 400;
  max-width: 1100px;
  margin: 0 auto;
  // background: rgba(234, 218, 255, 0.4);
}
.create_btn {
  cursor: pointer;
  height: 36px;
  display: flex;
  align-items: center;
  background: linear-gradient(90.3deg, #5A50D0 0.12%, #9F43D7 43.3%, #CF5A7C 66.71%, #EC811E 100%);
  border-radius: 6px;
  color: #fff;
  padding: 0 16px;
}
.main {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $headerHeight;
  z-index: 10;
}
.box {
  height: 100%;
  // max-width: $contentMaxWidth;
  padding: 0 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.notice {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin-right: 10px;
  padding: 0 3px;
  background: #fff;
  border-radius: 40px;
  border: 1px solid #EBE3FB;
  position: relative;
  cursor: pointer;
  .notice_tip {
    position: absolute;
    bottom: 5px;
    right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
  }
  .notice_num {
    margin-left: 4px;
    border-radius: 40px;
    background: red;
    color: #fff;
    font-size: 12px;
    padding: 0 8px;
  }
}
.dark {
  .nav {
    color: #fff;
  }
}
.box_h5 {
  display: none;
}
.logo {
  height: 28px;
}
.nav {
  margin-left: 60px;
  font-size: 16px;
  &:hover {
    font-weight: 600;
  }
}
.avatar {
  cursor: pointer;
  border: 1px solid #000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.pop {
  :global {
    .ant-popover-inner {
      border-radius: 4px;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}
.pop_body {
  width: 240px;
  // padding: 10px;
}
.pop_addr {
  cursor: pointer;
  height: 30px;
  padding: 0 10px;
  border-radius: 2px;
  background: #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pop_currency {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0;
  img {
    width: 20px;
  }
}
.pop_stake {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
}
.stake_icon {
  width: 16px;
  margin-right: 8px;
}
.pop_my_box {
  padding-bottom: 10px;
  .pop_my_item {
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 16px;

    a {
      display: inline-block;
      width: 140px;
      text-align: left;
    }
  }
  .pop_my_item:hover {
    background: #f4f4f4;
  }
  .h5_noShow {
    display: block;
  }
}
.pop_footer {
  border-top: $hr;
  text-align: center;
  color: #ef424c;
  line-height: 40px;
  .pop_logout {
    display: inline-block;
    width: 100px;
    text-align: left;
  }
}
.more {
  width: 21px;
  margin-left: 20px;
}
.nav_footer {
  display: none;
}
.nav_footer_item {
  width: 25%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
}
.nav_footer_img {
  width: 30px;
  height: 30px;
}
.nav_footer_text {
  font-size: 12px;
  line-height: 20px;
}
.nav_h5_body {
  position: fixed;
  z-index: 11;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}
.nav_h5_my {
  .pop_body {
    padding: 0;
    width: 100%;
  }
  .h5_pop_row {
    width: 60%;
    margin: 0 auto 16px;
  }
  .pop_my_box {
    padding-bottom: 0px;
  }
  .pop_my_item {
    width: 100%;
    text-align: center;
    height: 60px;
    line-height: 60px;
    border-top: $hr;
    padding: 0;
  }
  .h5_noShow {
    display: none;
  }
  .pop_footer {
    line-height: 60px;
  }
}
.nav_h5_about {
  width: 80%;
  text-align: center;
  margin: 0 auto;
}
.nav_h5_language {
  width: 50%;
  text-align: center;
  margin: 40px auto 0;
}
.select_box {
  width: 134px;
  margin-right: 10px;
  :global {
    .ant-select-selector {
      border-radius: 40px !important;
    }
  }
}
.select_option {
  border-radius: 4px;
  :global {
    .ant-select-item-option {
      border-bottom: $hr;
    }
    .ant-select-item-option-content {
      line-height: 40px;
    }
  }
}
.option_img {
  display: inline-block;
  height: 16px;
  border-radius: 50%;
}
.option_name {
  margin-left: 8px;
}

@media #{$smWidth} {
  .header_share {
    margin: 0 24px;
  }
  .main {
    height: $headerHeightH5;
  }
  .h5_hidden {
    display: none;
  }
  .box {
    display: none;
  }
  .box_h5 {
    height: 100%;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav_footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 70px;
    background-color: #fff;
    border-top: $hr;
    display: flex;
  }
}
