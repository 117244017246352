@import "assets/css/_variables.scss";

.bg_box {
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;
}
.top_box {
  height: 320px;
  background: url(../../assets/img/marketplace/top_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px 80px;
  padding: 60px 50px;
  color: #fff;
}
.top_h1 {
  font-size: 48px;
  font-weight: 600;
}
.top_p {
  max-width: 550px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;
}
.btn_buy, .btn_sell {
  width: 140px;
  height: 40px;
  line-height: 38px;
  border-radius: 6px;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  &:hover {
    color: #fff;
  }
}
.btn_buy {
  border: 1px solid #845DA4;
  background: #2F0936;
  margin-right: 10px;
}
.btn_sell {
  border: 1px solid #845DA4;
  background: #440C4E;
}

.top_collection {
  width: 100%;
  // height: 830px;
  padding-bottom: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 99;

  .page_title {
    max-width: 1440px;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 40px;
    font-weight: 600;
    color: #fff;
  }
  .collection_list {
    max-width: 1440px;
    height: 380px;
    margin: auto;
    padding: 16px 50px 16px 50px;
    border-radius: 6px;
    backdrop-filter: blur(20px);
    background: rgba(0, 0, 0, 0.3);
  }
  .collection_list_game {
    margin-top: 20px;
    height: auto;
  }
  .collection_content {
    display: flex;
  }
  .collection_content_h5 {
    display: none;
  }
  .collection_title {
    font-size: 20px;
    line-height: 30px;
    color: #fff;
    font-weight: 600;
  }
  .collection_view {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }
  .collection_hot {
    width: 250px;
    cursor: pointer;
    .hot_cover {
      position: relative;
      border: 1px solid #fff;
      border-radius: 4px;
      width: 250px;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .cover {
        width: 100%;
        height: auto;
      }
      .hot {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 60px;
      }
    }
  }
  .collection_item {
    width: 250px;
    height: 120px;
    display: flex;
    margin-bottom: 40px;
    cursor: pointer;

    .item_img {
      border: 1px solid #fff;
      border-radius: 4px;
      max-width: 120px;
      min-width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .img {
        width: 100%;
        height: auto;
        min-height: 100%;
      }
    }

    .item_txt {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 10px;
      color: #fff;
    }
    .item_name {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .collection_content_game {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .collection_icon {
    margin-right: 10px;
  }
  .chainType {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
  }
  .collection_game {
    position: relative;
    cursor: pointer;
    width: 100%;
    border: 1px solid #fff;
    overflow: hidden;
    border-radius: 4px;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../assets/img/defaultImg.jpg);
  }
  .collection_creator {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
    display: flex;
    padding: 14px;
    align-items: center;
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #fff;
  }
  .name {
    flex: 1;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
  }
}

.search_box {
  height: 60px;
  color: #fff;
  border-bottom: 2px solid #FFFFFF;
  box-shadow: 0 5px 6px rgba(77, 31, 114, 0.15);
  filter: drop-shadow(0px 5px 20px rgba(77, 31, 114, 0.15));
}
.search_content {
  max-width: 1440px;
  padding: 0 80px;
  margin: 0 auto;
  height: 100%;
  color: #000;

  .type_label {
    cursor: pointer;
    margin-right: 20px;
    height: 60px;
    line-height: 56px;
    text-align: center;
    min-width: 70px;
  }
  .type_active {
    border-bottom: 4px solid #845DA4;
  }
}
.search_content_h5 {
  display: none;
}
.type_label {
  cursor: pointer;
  margin-right: 30px;
}
.type_active {
  font-weight: 600;
}
.select {
  width: 180px;
}

.main {
  max-width: 1440px;
  margin: 0 auto;
  padding: 32px 70px 80px;
  min-height: calc(100vh - 70px);
}
.list_box {
  display: flex;
  // justify-content: center;
  flex-wrap: wrap;
}
.loading_skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 276px;
  border-radius: 4px;
  padding: 2px;
  margin: 8px;
  cursor: pointer;
  box-shadow: 0 0 0 1px #dedede;
  background: #fff;

  .chainType {
    width: 100%;
    height: 24px;
    margin-bottom: 5px;
    color: #fff;
  }
  .loading_img {
    position: relative;
    width: 180px;
    padding-bottom: 180px;
  }

  :global {
    .ant-skeleton-element {
      position: absolute;
      top: 0;
      width: 100%;
    }
    .ant-skeleton-image {
      width: 180px;
      height: 180px;
      margin: auto;
    }
    .ant-skeleton-title {
      margin-top: 10px;
    }
    .ant-skeleton-content {
      padding-left: 8px;
    }
    .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
      margin-top: 0;
    }
  }
}
.marketplace_no_data {
  height: calc(100vh - 230px);
}


@media (max-width: 1440px) and (min-width: 768px) {
  .top_collection {
    .page_title {
      max-width: 1300px;
    }
    .collection_list {
      max-width: 1300px;
      padding: 16px 40px 16px 40px;
    }
    .collection_hot {
      width: 230px;
      .hot_cover {
        width: 230px;
        height: 230px;
      }
    }
    .collection_item {
      width: 240px;
      height: 110px;
      .item_img {
        max-width: 110px;
        min-width: 110px;
        height: 110px;
      }
    }
  }
}

@media #{$smWidth} {
  .btn_buy, .btn_sell {
    width: 45%;
  }
  .bg_box {
    margin-top: -60px;
  }
  .top_box {
    background-position: 85% center;
    border-radius: 0;
    margin: 0;
    padding: 80px 40px 40px;
    height: auto;
  }
  .top_collection {
    height: auto;
    .avatar {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
    .collection_creator {
      padding: 5px;
    }
    .name {
      font-size: 10px;
    }
    .page_title {
      // display: none;
      padding-left: 10px;
      font-size: 30px;
    }
    .collection_content {
      display: none;
    }
    .collection_content_game {
      margin-right: 0;
    }
    .collection_content_h5 {
      display: flex;
    }
    .collection_list {
      padding: 16px 10px;
      height: auto;
    }
    .collection_hot {
      width: 114px;
    }
    .collection_item {
      width: 114px;
      flex-direction: column;
      // margin-bottom: 30px;
      margin: 0 auto 40px auto;

      .item_img {
        min-width: 114px;
        min-height: 114px;
      }

      .item_txt {
        padding: 4px;
      }
      .item_name {
        font-size: 12px;
        line-height: 16px;
      }
      .item_count {
        display: none;
      }
    }
  }
  .main {
    padding: 24px 15px 85px;
  }
  .search_box {
    height: auto;
    min-height: 70px;
    border: none;
  }
  .search_content {
    display: none;
  }
  .search_content_h5 {
    background-color: #000;
    display: block;
    padding: 0 20px;
    align-items: center;
    min-height: 70px;
  }
  .title_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }
  .page_title {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }
  .loading_skeleton {
    width: calc(50% - 12px);
    margin: 5px;
    height: auto;

    .loading_img {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
    }
    :global {
      .ant-skeleton-element {
        width: 100%;
        height: 100%;
        padding-left: 8px;
        padding-right: 8px;
      }
      .ant-skeleton-image {
        width: 100%;
        height: 100%;
      }
      .ant-skeleton-title {
        margin-top: 6px;
        margin-bottom: 0.3em;
      }
      .ant-skeleton-content {
        padding-left: 8px;
      }
      .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
        margin-top: 0;
        margin-bottom: 0.5em;
      }
    }
  }
}
