@import "assets/css/_variables.scss";

.card_list {
  display: flex;
  flex-wrap: wrap;
}

.card {
  position: relative;
  // width: 460px;
  width: 312px;
  margin-right: 20px;
  margin-bottom: 80px;
  // padding: 40px 20px 30px 20px;
  padding-bottom: 10px;
  border: 1px solid #dedede;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
}

.card_img {
  position: absolute;
  top: -40px;
  left: 40px;
}

.card_img_stake {
  position: absolute;
  left: 30px;
  width: 80px;
  height: 80px;
  border: 2px solid #333;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_img_trans {
  position: absolute;
  top: 25px;
  left: 98px;
  z-index: 9;
}

.card_img_earn {
  position: absolute;
  left: 110px;
  width: 80px;
  height: 80px;
  border: 2px solid #333;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_title {
  padding: 10px 10px 0;
  line-height: 1.3;
}

.card_time {
  padding: 0px 10px 0 30px;
  // background: #f4f4f4;
  border-radius: 4px;
}
.card_time:first-child {
  border-right: 1px solid #dedede;
}

.tag_closed {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px 10px;
  border-radius: 4px;
  background: #9a9a9a;
  color: #fff;
  font-size: 12px;
}
.tag_active {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px 10px;
  border-radius: 4px;
  background: #FFF3F3;
  color: #fff;
  font-size: 12px;
}
.active_txt {
  background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.card_closed {
  color: #9a9a9a !important;
}
.card_main {
  background: #f4f4f4;
}

.flex_v_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_fs {
  font-size: 24px;
}

@media #{$smWidth} {
  .card_list {
    display: flex;
    flex-wrap: wrap;
  }

  .card {
    width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
  }

  .card_fs {
    font-size: 16px;
  }
}




.farm_detail {
  .pool_info_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #dedede;
  }
  .box_item {
    width: 80%;
    margin: auto;
  }
  .flex_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex_left_center {
    display: flex;
    align-items: center;
  }

  .card_baseInfo {
    display: flex;
    border: 1px solid #dedede;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  }

  .card_flex_box {
    flex: 1;
    padding: 20px 0;
  }

  .card_fs {
    font-size: 32px;
  }

  .card_tag_dfa {
    width: 28px;
    height: 28px;
  }
  .card_tag_dfa_s {
    width: 26px;
    height: 26px;
  }

  .total_staked {
    border-left: 1px solid #dedede;
  }

  .card_table {
    display: block;
    border: 1px solid #dedede;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  }
  .unstake_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
  }
  .unstake_item_claimable {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #FFA858;
  }

  .add_badge_num {
    display: inline-block;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 6px;
  }
  .coming_soon {
    width: 200px;
    height: 40px;
    margin: 10px auto 0 auto;
    line-height: 40px;
    border-radius: 6px;
    background: #f4f4f4;
    font-size: 14px;
  }

  .btn_text {
    background: none;

    span {
      text-decoration: underline;
    }
  }

  .btn_text:hover {
    background: none;

  }

  .table_head {
    padding: 10px;
    background: #000;
    color: #fff;
  }

  .table_badge {
    width: 10%;

    img {
      border: 2px solid #000;
      border-radius: 4px;
    }
  }

  .table_badgeType {
    width: 30%;
  }

  .table_col {
    width: 15%;
  }

  .table_btn {
    width: 30%;
  }

  .table_row {
    padding: 10px;
  }

  .table_row_bg {
    padding: 10px;
    background: #f9f9f9;
  }

  .boost_active {
    display: inline-block;
    background: #FFD465;
    width: 60px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 15px;
  }

  .boost {
    background: #E5E5E5;
    width: 60px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 15px;
  }

 

  .color_font {
    color: #9a9a9a;
  }

  

  .btn_border_l {
    width: 120px;
    height: 40px;
    border: 1px solid #333;
    // border-radius: 6px;
    background: #fff;
    font-size: 16px;

  }

  .btn_border_l:hover {
    height: 40px;
    border: 1px solid #333;
    // border-radius: 6px;
  }

  .btn_border {
    height: 32px;
    border: 1px solid #333;
    border-radius: 6px;
    background: #fff;
  }

  .btn_border:hover {
    height: 32px;
    border: 1px solid #333;
    border-radius: 6px;
    background: #fff;

  }

  .btn_l {
    width: 120px;
    height: 40px;
    font-size: 16px;
    padding: 0;
  }

  .btn_stakes {
    display: flex;
    justify-content: space-around;
  }

  .color_9a {
    color: #9a9a9a;
  }
}
.modal_main {
  padding: 20px;
  border: 1px solid #dedede;
  border-radius: 6px;
  background: #f9f9f9;
}
.input {
  width: 60%;
  border: none;
  background: #f9f9f9;
  -webkit-appearance: none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;

}

@media #{$smWidth} {
  .farm_detail {
    .card_baseInfo {
      display: flex;
      flex-direction: column;
    }
    .pool_info_item {
      border-right: 0px;
    }
    .unstake_item {
      flex-direction: column;
    }
    .unstake_item_claimable {
      flex-direction: column;
    }
    .p10_h5 {
      padding: 10px;
    }

    .card_flex_box {
      flex: 1;
      padding: 10px 0;
    }

    .card_h5_line {
      border-top: 1px solid #dedede;
    }

    .total_staked {
      border-left: none;
      border-top: 1px solid #dedede;
    }

    .table_badge {
      width: 20%;
    }

    .table_badgeType {
      width: 0%;
    }

    .table_col {
      width: 20%;
    }

    .table_btn {
      width: 40%;
    }

    .boost_active {
      width: 44px;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
    }

    .boost {
      width: 44px;
      height: 28px;
      line-height: 28px;
    }
  }
}