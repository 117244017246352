@import "assets/css/_variables.scss";
.badgeBox {
  display: inline-block;
  width: 280px;
  height: 280px;
  border: 1px solid #dedede;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  .badgeBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .badgeSrc {
    width: 100%;
    max-height: 100%;
    display: block;
  }
}
.lock {
  position: absolute;
  top: calc(50% - 13px);
  left: calc(50% - 12px);
  z-index: 1; 
}
.badgeLocked {
  // filter: grayscale(100%);
	filter: blur(3px);
}

@media #{$smWidth} {
  .badgeBox {
    // width: 100%;
    width: 138px;
    height: 138px;
  }
}