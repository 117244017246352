@import "assets/css/_variables.scss";

.main {
  border-radius: 6px;
  // background: #f9f9f9;
  border: $hr;
  :global {
    textarea.ant-input {
      padding: 0;
      background-color: #fff;
    }
  }
}
.text_box {
  padding: 20px 20px 34px;
}
.handle_box {
  // border-top: $hr;
  height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}
.pop_emoji {
  :global {
    .ant-popover-inner-content {
      padding: 0;
    }
  }
}
.fence {
  display: inline-block;
  height: 20px;
  border-right: $hr;
  margin: 0 16px;
}
.permission_select {
  margin-right: 10px;

  :global(.ant-select-selector) {
    height: 40px !important;
    padding: 0 15px 0 20px !important;
  }
  :global(.ant-select-selection-item) {
    line-height: 38px !important;
  }
}
.preview_box {
  position: relative;
  margin: 0 20px 20px;
  width: 320px;
  max-height: 400px;
  overflow: hidden;
  border: $hr;
  border-radius: 6px;
}
.preview_img {
  width: 100%;
}
.delete_box {
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
