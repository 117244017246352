@import "assets/css/_variables.scss";
$rightWidthL: 300px;
$rightWidthS: 260px;

.content_box {
  flex: 1;
  // background-color: #f6f6f6;
  min-height: calc(100vh - 70px);
  padding: 10px 20px;
}
.nav_h5 {
  display: none;
}
.content_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px; 
  cursor: pointer;
}
.btn_post {
  width: 100px;
  height: 34px;
}
.topic_item {
  max-width: 680px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  margin-bottom: 8px;
  padding: 20px 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
}
.item_hot {
  width: 80px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  color: #fff;
  font-weight: 500;
}
.item_title_hot {
  font-size: 20px;
  font-weight: 500;
}
.item_title {
  font-size: 16px;
  font-weight: 400;
}
.activity_box {
  margin-top: 10px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}
.topic_item_h5 {
  display: none;
}

@media #{$smWidth} {
  .content_box {
    padding: 0;
  }
  .nav_h5 {
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 8px 20px;
    font-size: 18px;
    border-bottom: 1px solid #dedede;
  }
  .topic_item {
    display: none;
  }
  .topic_item_h5 {
    display: flex;
    padding: 20px;
    font-size: 14px;
    background: #fff;
    border-bottom: 1px solid #dedede;
  }
  .btn_post {
    width: 90px;
    height: 30px;
  }
}
