@import "assets/css/_variables.scss";

.collection {
  padding: 0 20px;
  max-width: 1300px;
  margin: 20px auto 0;
}
.filter {
    border-left: 1px solid #dedede;
}
.modal_df {
    display: flex;
  
    .modal_select {
      width: 50%;
      margin-right: 10px;
    }
  }
  .collection_no_data {
    height: calc(100vh - 570px);
  }
  @media #{$smWidth} {
    .modal_df {
        display: flex;
        flex-direction: column;
        .modal_select {
          width: 100%;
        }
      }
  }