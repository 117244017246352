@import "assets/css/_variables.scss";

.radio:global(.ant-radio-group) {
    border-radius: 20px;
    padding: 0;
    line-height: 1;

    :global(.ant-radio-button-wrapper) {
        border: none !important;
        padding: 10px 10px;
        font-weight: 500;
        height: auto;
        line-height: 21px;
        color: #000;
        font-size: 14px;
        text-align: center;
        margin-right: 10px;
        margin-bottom: 10px;
        background: #F6F6F6;
        border-radius: 4px;

        &:last-child {
            margin-right: 0;
        }
    }

    :global(.ant-radio-button-wrapper-checked) {
        background: linear-gradient(225deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
        color: #fff !important;
    }

    :global(.ant-radio-button-wrapper:not(:first-child)::before) {
        content: '';
        background-color: transparent;
    }
}

@media #{$smWidth} {
    .radio:global(.ant-radio-group) {
        :global(.ant-radio-button-wrapper) {
            padding: 6px 10px;
            margin-right: 10px;
        }
    }
}