@import "assets/css/_variables.scss";

.space_between {
  display: flex;
  justify-content: space-between;
}
.card_list {
  display: flex;
  flex-wrap: wrap;

}
.df_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.table {
  border: 1px solid #000;
  border-radius: 10px;
}
.table_header {
  background: #000;
  color: #fff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.table_item_gray {
  background: #F4F4F4;
}
.des {
  li {
    list-style-type: disc;
    list-style-position: inside;
  }
  .des_content {
    background: #f4f4f4;

  }
  .des_img {
    width: 60%;
  }
}
.c_blue {
  color: #1890ff;
}

@media #{$smWidth} {
  .card {
    width: 88%;
    margin: 0 auto 15px auto;
  }
  .des {
    .des_img {
      width: 100%;
    } 
  }
}
.mr5 {
  margin-right: 5px;
}