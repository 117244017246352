@import "assets/css/_variables.scss";

.vote_banner {
  padding: 10px 20px 10px 40px;
  background: linear-gradient(180deg, #FFFFFF -7.08%, #F7F7F7 100%);
  border: 1px solid #DEDEDE;
  box-sizing: border-box;
  border-radius: 4px;
}

.vote_filter_pc {
  display: block;
}
.vote_filter_h5 {
  display: none;
}
.vote_filter {
  .radio:global(.ant-radio-group) {
    border-radius: 20px;
    padding: 0;
    line-height: 1;

    :global(.ant-radio-button-wrapper) {
        border: none !important;
        padding: 0px;
        font-weight: 400;
        height: auto;
        line-height: 30px;
        color: #000;
        font-size: 14px;
        text-align: center;
        margin-right: 10px;
        // margin-bottom: 10px;
        // background: #F6F6F6;
        border-radius: 4px;
        width: 80px;
        text-align: center;
        height: 30px;

        &:last-child {
            margin-right: 0;
        }
    }
    :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within) {
      box-shadow: none;
    }

    :global(.ant-radio-button-wrapper-checked) {
        background: #000;
        color: #fff !important;
    }

    :global(.ant-radio-button-wrapper:not(:first-child)::before) {
        content: '';
        background-color: transparent;
    }
  }
}

.vote_header {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: #000;
  color: #fff;
}
.vote_title_box {
  display: flex;
  align-items: center;
}
.vote_title_tip {
  border-radius: 20px;
  padding-left: 20px;
  a:hover {
    color: #1673E1;
  }
}

.vote_item_creat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 160px;
  border: 2px dotted #dedede;
}
.creat_tip_mb {
  margin-bottom: 10px;
}

.vote_item {
  position: relative;
  width: 100%;
  height: 160px;
  padding: 20px;
  border: 1px solid #dedede;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;

  .sip{
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    color: #9a9a9a;
    background-color: #f4f4f4;
    border-bottom: 1px solid #dedede;
    border-right: 1px solid #dedede;
    border-bottom-right-radius: 12px;
  }

  .vote_item_title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .vote_item_info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.4;
  }

  .vote_item_footer {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding: 10px;
  }

  .item_status {
    padding: 0 10px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 6px;
  }

  .status_voting {
    background: #FFF3F3;
  }
}

.vote_creat {
  :global {
    .ant-radio-group {
      width: 100%;
      display: flex;
    }
    .ant-radio-wrapper {
      width: 50%;
      padding: 8px 10px;
      background: #f4f4f4;
      border-radius: 6px;
    }
    .ant-radio-wrapper-checked {
      background: #000;
      color: #fff;
      font-weight: 500;
    }
    .ant-radio-inner::after {
      background: #000;
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: transparent;
    }

    .ant-picker {
      width: 100%;
    }

    .EasyMDEContainer .CodeMirror {
      min-height: 400px;
    }
  }
  .select_contract_modification {
    .w300 {
      width: 300px;
    }
    :global {
     .ant-form-item {
        width: 50%;
        padding-right: 8px;
      }
      .ant-select-single .ant-select-selector {
        height: 60px;
      }
      .ant-select-single .ant-select-selection-search-input {
        height: 58px;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 58px;
      }
      
    }
    .fees {
      width: 100%;
      height: 60px;
      border: 1px solid #dedede;
      border-radius: 4px;
    }
    .current_fees {
      width: 150px;
      padding: 5px 10px;
      background: #f9f9f9;
    }
    .to_icon {
      position: absolute;
      width: 28px;
      height: 28px;
      top: 16px;
      left: 136px;
      text-align: center;
      line-height: 28px;
      border: 1px solid #dedede;
      border-radius: 50%;
      background: #fff;
      z-index: 9;
    }
    .target_fees {
      padding: 5px 25px;
      border-left: 1px solid #dedede;
      :global {
        input[type=number] {
          -moz-appearance:textfield;
        }
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
      }
    }
    :global {
      .ant-input, .ant-input-affix-wrapper {
        padding: 0;
        border: none;
        background-color: #fff;
      }
    }
  }
  
}

.creat_tip {
  padding: 10px 20px;
  background: #f4f4f4;
  border-radius: 6px;
}

.space_between {
  display: flex;
  justify-content: space-between;
}

.vode_detail {
  .vote_info {
    position: relative;
    padding: 20px;
    border: 1px solid #DEDEDE;
    border-radius: 4px;
    .sip{
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 12px;
      color: #9a9a9a;
      background-color: #f4f4f4;
      border-bottom: 1px solid #dedede;
      border-right: 1px solid #dedede;
      border-bottom-right-radius: 12px;
      border-top-left-radius: 4px;
    }
  }

  .vote_status_bg {
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 6px;
  }

  .detail_head {
    background: #DEDEDE;
    margin-left: -20px;
    margin-right: -20px;
    padding: 3px 20px;
  }

  .vote_yes {
    font-size: 18px;
    font-weight: 600;
    color: #229C1F;
  }

  .vote_no {
    font-size: 18px;
    font-weight: 600;
    color: #E84444;
  }

  :global {
    .ant-progress-show-info .ant-progress-outer {
      padding-right: 0;
    }

    .ant-progress-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 30px;
      line-height: 30px;
      color: #fff;
      font-weight: 500;
      text-align: center;
    }
  }

  .vote_progress {
    margin-top: 10px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px 0px 20px;
    background: #f8f8f8;
    border-bottom: 1px solid #DEDEDE;
  }
  .valid_votes_tip {
    margin-top: 16px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 5px;
    background: #f2f2f2;
    text-align: center;
  }

  .progress_percent {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFF;
    font-weight: 500;
  }
  .simple_voted_box {
    width: 100%;
    margin-top: 10px;
    border-radius: 4px;
    background: #fff;
    font-size: 12px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);

    .simple_voted_head {
      padding: 4px 20px;
    }

    .simple_voted_item {
      height: 36px;
      line-height: 36px;
      padding: 0 20px;
    }
    .simple_no_data {
      height: 108px;
      text-align: center;
      line-height: 108px;
    }
  }
  .bt_hr {
    border-top: 1px solid #dedede;
  }
  .bb_hr {
    border-bottom: 1px solid #dedede;
  }
  .bb_hr_f4 {
    border-bottom: 1px solid #f4f4f4;
  }

  .vote_content {
    word-break: break-word;
    li {
      margin-left: 20px;
    }
    .targetFeeTip {
      margin-top: 20px;
      padding: 5px 10px;
      background: #F9F9F9;
      border: 1px solid #DEDEDE;
      box-sizing: border-box;
      border-radius: 3px;
    }
  }

  .owner_vote {
    border: 1px solid #DEDEDE;
    border-radius: 4px;

    :global {
      .ant-steps-item-title {
        display: flex;
        justify-content: space-between;
      }
      .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #229C1F;
      }
      .ant-steps-item-subtitle {
        cursor: pointer;
      }
      
    }
  }

  .box_header {
    background: #f4f4f4;
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    // padding: 10px 20px;
    border-bottom: 1px solid #f4f4f4;
    font-weight: 600;
  }

  .owner_vote_main {
    padding: 20px;
  }

  .owner_status {
    text-align: center;
    padding: 10px 0;
  }

  .c_yes {
    color: #229C1F;
  }

  .c_no {
    color: #E84444;
  }

  .c_blue {
    color: #1673E1;
  }

  .vote_choose {
    :global {
      .ant-radio-group, .ant-space {
        width: 100%;
      }
      .ant-space-item {
        width: 100%;
      }
      .ant-radio-wrapper {
        width: 100%;
        padding: 8px 10px;
        background: #f4f4f4;
        border-radius: 6px;
      }
      .ant-space .ant-space-item:first-child {
        .ant-radio-wrapper-checked {
          background: #229C1F;
          color: #fff;
          font-weight: 500;
        }
        .ant-radio-inner::after {
          background: #229C1F;
        }
      }
      .ant-space .ant-space-item:last-child {
        .ant-radio-wrapper-checked {
          background: #E84444;
          color: #fff;
          font-weight: 500;
        }
        .ant-radio-inner::after {
          background: #E84444;
        }
      }
      .ant-radio-checked .ant-radio-inner {
        border-color: transparent;
      }
      
    }
  }

  .vote_result_no {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #FFE9E9;
    color: #E84444;
  }

}

.totalSplicedModal {
  text-align: center;

  :global {
    .ant-modal-title {
      text-align: center;
    }

    .ant-modal-footer {
      text-align: center;
    }
  }

  :global {
    .ant-table-thead>tr>th {
      line-height: 1.5 !important;
      background-color: #e5e5e5 !important;
      color: #000 !important;
      font-weight: 500;

      &::before {
        display: none;
      }
    }

    .ant-table-tbody>tr>td {
      line-height: 26px !important;
    }

    .rowBgWhite {
      background: #fff !important;
    }

    .rowBgGray {
      background: #f9f9f9 !important;
    }
  }

}

.vote_yes {
  font-size: 24px;
  font-weight: 600;
  color: #229C1F;
}

.vote_no {
  font-size: 24px;
  font-weight: 600;
  color: #E84444;
}
.count_down {
  span {
    display: inline-block;
    width: 86px;
  }
}

@media #{$smWidth} {
  .vote_banner {
    padding: 10px;
  }
  .vote_title_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .vote_filter {
    margin-top: 10px;
  }
  .vote_filter_pc {
    display: none;
  }
  .vote_filter_h5 {
    display: block;
  }
  .m_f_10 {
    margin: 0 -10px;
  }
  .creat_tip_mb {
    margin-bottom: 4px;
  }
  .vote_creat {
    .select_contract_modification {
      :global {
       .ant-form-item {
          width: 100%;
          padding-right: 8px;
        }
      }
    }
  }
  .valid_votes_tip {
    font-size: 12px;
  }
}


/********** vote **************/
:global {
  .status_voting {
    background: #FFF3F3;
  }

  .status_soon {
    background: #FFF3F3;
  }

  .status_executed, .status_passed, .status_queued {
    background: #D2F8D4;
  }

  .status_defeated {
    background: #FFE9E9;
  }

  .status_canceled, .status_failed {
    background: #E5E5E5;
  }

  .status_voting_txt {
    background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 500;
  }

  .status_voting_title {
    background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 500;
  }

  .status_executed_title, .status_passed_title, .status_queued_title {
    color: #229C1F;
  }

  .status_soon_txt {
    background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 500;
  }

  .status_executed_txt, .status_passed_txt, .status_queued_txt {
    color: #229C1F
  }

  .status_defeated_txt {
    color: #E84444;
  }

  .status_canceled_txt,.status_failed_txt  {
    color: #000;
  }

  .status_voting_time {
    color: #9A9A9A;
    // background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
    // -webkit-background-clip: text;
    // background-clip: text;
    // color: transparent;
    // font-weight: 500;
  }

  .status_soon_time {
    color: #9A9A9A;
    // background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
    // -webkit-background-clip: text;
    // background-clip: text;
    // color: transparent;
    // font-weight: 500;
  }

  .status_executed_time,.status_passed_time,.status_queued_time {
    color: #9A9A9A;
  }

  .status_defeated_time {
    color: #9A9A9A;
  }

  .status_canceled_time,.status_failed_time {
    color: #9A9A9A;
  }

  .vote_list_yes {
    color: #229C1F;
  }

  .vote_list_no {
    color: #E84444;
  }

  .vote_list .vote_item_bg {
    background: #f9f9f9;
    box-shadow: none;
  }
}

/********** vote end **************/