@import "assets/css/_variables.scss";
$rightWidthL: 300px;
$rightWidthS: 260px;

.content_box {
  flex: 1;
  min-height: calc(100vh - 70px);
  padding: 10px 20px;
}
.back_box {
  padding: 10px 20px;
  border-bottom: $hr;
  font-size: 0;
}
.activity_box {
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}
.publish_box {
  padding: 20px 40px;
}


.tab_box {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.tab_btn {
  text-align: center;
  width: 120px;
  height: 40px;
  line-height: 40px;
  color: #9a9a9a;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 20px;
}
.active {
  color: #fff;
  background-color: #000;
}

@media #{$smWidth} {
  .content_box {
    padding: 0;
  }
}
