@import "assets/css/_variables.scss";

.nftBorder {
  background-image: linear-gradient(226.11deg, #5A50D0 0%, #5D4ADD 11.46%, #9F43D7 41.46%, #CF5A7C 66.48%, #EC811E 83.97%, #FFA858 97.92%);
  border-radius: 50%;
  position: relative;
}
.bg_white {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #fff;
  border-radius: 50%;
}

.avatar {
  border-radius: 50%;
  cursor: pointer;
}
.add_border {
  border: 2px solid #dedede;
}

.avatar_box {
    position: relative;
    display: block;

    .tag {
        position: absolute;
        bottom: 0px;
        right: 10px;
        z-index: 2;
    }
}
