@import "assets/css/_variables.scss";

.page_main {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 10px;
}

.events_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card_box {
  position: relative;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  width: 630px;
  height: 300px;
  padding: 5px;
  background: #fff;
  margin-bottom: 20px;
}


.box_left {
  padding: 25px 31px;

  .box_name {
    background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 600;
  }

  .box_title {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 1.2;
  }

  .box_intro {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    white-space: pre-wrap;
  }
}

.box_img {
  width: 290px;
  height: 290px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.events_btn {
  width: 200px;
  position: absolute;
  bottom: 30px;
  left: 36px;
}

@media #{$smWidth} {
  .events_btn {
    position: unset;
    width: 100%;
  }

  .page_main {
    padding: 0 20px;
  }

  .card_box {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .box_left {
    padding: 20px 20px;

    .box_name {
      background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      font-weight: 600;
    }

    .box_title {
      display: flex;
      align-items: center;
      height: auto;
      font-size: 16px;
      font-weight: 600;
    }

    .box_intro {
      height: auto;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }

    .box_btn {
      width: 100%;
    }
  }

  .box_img {
    width: 100%;
    height: 100%;
  }
}