@import "assets/css/_variables.scss";

.home {
    max-width: 1280px;
    // max-width: 100%;
    height: 100%;
    margin: auto;
    padding-top: 20px;
    background-repeat: no-repeat;
    background-size: cover;

    .home_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 320px;
        margin: auto;
        // padding: 28px;
        // border: 2px solid #FFFFFF;
        // backdrop-filter: blur(32px);
        // border-radius: 10px;
        // background: linear-gradient(93.16deg, #FFFFFF 0.26%, #E8D6FF 99.64%);

        // .header_left {
            // padding-left: 20px;
        // }

        .header_title {
            font-weight: 600;
            font-size: 48px;
            line-height: 1.3;
            background: linear-gradient(270.02deg, #220638 -10.51%, #7B2372 99.95%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
        .header_content {
            width: 400px;
            font-size: 18px;
            background: linear-gradient(270.02deg, #220638 -10.51%, #7B2372 99.95%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
        .header_allEvents {
            width: 250px;
            height: 50px;
            margin-top: 18px;
            margin-right: 14px;
            background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
            color: #fff;
            font-size: 16px;
        }
        .header_prupleBtn {
          display: inline-block;
          width: 200px;
          height: 50px;
          margin-top: 18px;
          border: 1px solid #6F4293;
          color: #6F4293;
          background: #f5efff;
          font-size: 16px;
          font-weight: 500;
        }
        .header_right {
            width: 740px;
            overflow: hidden;
        }
        .swiperList {
            width: 530px;
            margin: auto;
            overflow: visible;

            .swiper_banner {
                width: 530px;
                height: 264px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }

            }
            :global {
                .swiper-slide {
                    border: 2px solid #fff;
                    border-radius: 10px;
                    overflow: hidden;
                    background-position: center;
                    background-size: cover;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .swiper-slide-prev {
                    transform: scale(0.8) translateX(94%) !important;
                    // transform: ;
                    opacity: 0.6;
                }
                .swiper-slide-next {
                    transform: scale(0.8) translateX(-94%) !important;
                    opacity: 0.6;
                    
                }
                .swiper-slide-active {
                    background-position: center;
                    background-size: cover;
                    z-index: 8;
                }
                .swiper-button-next, .swiper-button-prev {
                    width: 46px;
                    height: 46px;
                    margin-top: -23px;
                    background: rgba(50, 50, 50, 0.3);
                    border: 2px solid #FFFFFF;
                    border-radius: 50%;
                    backdrop-filter: blur(7px);
                }
                .swiper-button-next:after, .swiper-button-prev:after {
                    font-weight: 600;
                    font-size: 22px;
                    color: #fff;
                }
                .swiper-button-prev, .swiper-rtl .swiper-button-next {
                    left: -102px;
                }
                .swiper-button-next, .swiper-rtl .swiper-button-prev {
                    right: -102px;
                }
            }
        }
    }
    .banner_h5 {
        display: none;
    }

    .title {
        font-weight: 600;
        font-size: 26px;
        line-height: 1.3;
        background: linear-gradient(270.02deg, #220638 -10.51%, #7B2372 99.95%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    .title_icon {
        width: 35px;
    }
    .title_noSelected {
        color: #876D9B;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #876D9B;
        background-clip: text;
    }
    .list_box {
        margin-left: -10px;
        margin-right: -10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .card_nft_box {
      width: 16.666666%;
      padding: 10px;
    }
    .recent_badge {
        .loading_skeleton {
            :global {
                .ant-skeleton-image {
                width: 300px;
                height: 300px;
                margin-bottom: 10px;
                }
            }
        }
        
    }
    .collection_menu {
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid #E0CDEB;
    }
    .game_viewall {
        display: block;
    }
    .gaming_nft {
      margin-top: 20px;
        .game_list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
        }
        .list_spaceBetween {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .nft_item {
                margin-right: 0;
            }
        }
        .nft_item {
            cursor: pointer;
            position: relative;
            width: 100%;
            padding-bottom: 100%;
            border-radius: 10px;
            background-position: center;
            background-size: cover;
            overflow: hidden;
            border: 2px solid #fff;

            .item_image {
                width: 200px;
                height: 200px;
            }
            .item_info {
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                padding: 20px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40.1%, #000000 84.9%);
            }
            .item_name {
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #fff;
            }
            .item_count {
                padding-top: 6px;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
            }
        }
        .viewAll_pc {
            display: block;
        }
        .viewAll_h5 {
            display: none;
        }
        .loading_skeleton_game {
            :global {
                .ant-skeleton-image {
                    width: 200px;
                    height: 200px;
                    margin-right: 0px;
                }
            }
        }
    }
    .articles {
        .article_item {
            width: auto;
            margin: 0 12px 20px;
            border: 2px solid #FFFFFF;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
            border-radius: 10px; 
            background-color: #fff;
            overflow: hidden;   
            z-index: 22;


            .item_content {
                width: 408px;
                height: 208px;
                position: relative;

                .item_img {
                    width: 100%;
                    height: 208px;
                }
                .item_name {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    width: 100%;
                    height: 100%;
                    padding: 16px 18px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40.1%, #000000 84.9%);
                }
            }
            .item_intro {
                height: 40px;
                margin: 10px 16px;
                font-size: 12px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        :global { 
            .swiper{
                width: 100%;
                height: 100%;
            }
            .swiper-button-next, .swiper-button-prev {
                width: 46px;
                height: 46px;
                margin-top: -23px;
                background: rgba(50, 50, 50, 0.3);
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                backdrop-filter: blur(7px);
            }
            .swiper-button-next:after, .swiper-button-prev:after {
                font-weight: 600;
                font-size: 22px;
                color: #fff;
            }
            .swiper-button-prev, .swiper-rtl .swiper-button-next {
                left: -0px;
            }
            .swiper-button-next, .swiper-rtl .swiper-button-prev {
                right: -0px;
            }
        }
    }
}

.mb30_h5 {
    margin-bottom: 20px;
}
@media #{$smWidth} {
    .mb30_h5 {
        margin-bottom: 15px;
    }
    .home {
        width: 100%;
        padding: 16px;
        .home_header {
            flex-direction: column;
            height: auto;
            padding: 18px;

            .header_title {
                font-size: 44px;
                line-height: 1;
                padding-bottom: 10px;
            }
            .header_content {
                width: 100%;
            }
            .header_allEvents {
              display: none;
            }

            .header_right {
                display: none;
                // width: 340px;
            }
        }
        .banner_h5 {
            display: block;
            width: 100%;
            .swiperList {
                margin-top: 10px;
                // width: 340px;
                .swiper_banner {
                    margin: 0 10px;
                    overflow: hidden;
                    border-radius: 10px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
    
                }
            }
            :global {
                .swiper {
                    // overflow: visible;
                    width: 100%;
                }
                .swiper-wrapper {
                    // overflow: hidden;
                    width: 100%;
                }
                .swiper-button-next, .swiper-button-prev {
                    width: 30px;
                    height: 30px;
                    margin-top: -22px;
                    background: rgba(50, 50, 50, 0.3);
                    border: 2px solid #FFFFFF;
                    border-radius: 50%;
                    backdrop-filter: blur(7px);
                }
                .swiper-button-next:after, .swiper-button-prev:after {
                    font-weight: 600;
                    font-size: 12px;
                    color: #fff;
                }
                .swiper-button-prev, .swiper-rtl .swiper-button-next {
                    left: -0px;
                }
                .swiper-button-next, .swiper-rtl .swiper-button-prev {
                    right: -2px;
                }
            }
        }
        .title {
            font-size: 18px;
        }
        .title_icon {
            width: 20px;
            height: 20px;
        }
        .recent_badge {
            .loading_skeleton {
                :global {
                    .ant-skeleton-image {
                        width: 160px;
                        height: 160px;
                        margin-bottom: 10px;
                    }
                }
            }
            .list_box {
              margin: 0 -5px;
            }
            
            :global {
                .swiper-wrapper {
                    flex-wrap: wrap;
                }
                .swiper-slide {
                    width: 164px !important;
                    height: 164px;
                    margin: 3px;
                }
            }
        }
        .game_viewall {
            display: none;
        }
        .gaming_nft {
          margin-top: 20px;
            .game_list {
                justify-content: space-between;
                margin: 0 -5px;
            }
            .card_nft_box {
              width: 50%;
              padding: 5px;
            }
            .nft_item {
                .item_info {
                    padding: 14px;
                }
    
                .item_image {
                    width: 160px;
                    height: 160px;
                }
                .item_name {
                    font-size: 16px;
                }
                .item_count {
                    font-size: 14px;
                }
            }
            .viewAll_h5 {
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }
            .viewAll_pc {
                display: none;
            }
            .loading_skeleton_game {
                :global {
                    .ant-skeleton-image {
                        width: 160px;
                        height: 160px;
                        margin-right: 6px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .articles {
            .article_item {
                margin: 0 10px 20px;
                border: 2px solid #FFFFFF;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
                border-radius: 10px; 
                overflow: hidden;   
                z-index: 22;
    
                .item_content {
                    width: 100%;
                    height: 168px;
                    position: relative;
    
                    .item_img {
                        width: 100%;
                        height: 168px;
                    }
                }
                .item_intro {
                    // padding: 10px 16px;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
            :global { 
                .swiper{
                    overflow: hidden;
                }
                .swiper-button-next, .swiper-button-prev {
                    width: 30px;
                    height: 30px;
                    margin-top: -16px;
                    background: rgba(50, 50, 50, 0.3);
                    border: 2px solid #FFFFFF;
                    border-radius: 50%;
                    backdrop-filter: blur(7px);
                }
                .swiper-button-next:after, .swiper-button-prev:after {
                    font-size: 16px;
                }
                .swiper-button-prev, .swiper-rtl .swiper-button-next {
                    left: -2px;
                }
                .swiper-button-next, .swiper-rtl .swiper-button-prev {
                    right: -0px;
                }
            }
        }
        
    }
}
