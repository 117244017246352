.container {
  font-family: 'Poppins', sans-serif;
  position: relative;
}
.page_head_img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;

  .title {
      font-size: 36px;
      font-weight: 600;
      color: #fff;
  }
  .subtitle {
      margin-top: 20px;
      font-size: 16px;
      line-height: 26px;
  }
}
.title_mt {
  margin-top: 50px;
}
.subtitle {
  margin: 0 auto;
  max-width: 1010px;
  line-height: 1.5 !important;
}

.search_box {
  margin-top: 20px;
  text-align: center;
}
.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  // border-left: 1px solid #dedede;
}
.space_line, .space_line_search {
  height: 25px;
  width: 1px;
  margin: 0 25px;
  background-color: #dedede;
}


.search {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  width: 300px;
}
:global {
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #000;
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused  {
    border-color: #000;
    box-shadow: none;
  }
}

.filter_box {
  display: inline-block;
  color: #000;
  margin-left: 30px;
  font-size: 20px;
  cursor: pointer;
}
.btnBox {
  display: flex;
  justify-content: space-around;
}

:global(.subtitle_wrapper) {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

$max_item_width: 248px;
.content {
  margin-top: 100px;
  width: 100%;
  max-width: $max_item_width * 6;
}

.item_wrap {
  width: 50%;
}

.modal_df {
  display: flex;

  .modal_select {
    width: 50%;
    margin-right: 10px;
  }
}
.text666{
  color: rgb(19, 169, 255);
}
@media screen and (max-width: 750px) {
  .page_head_img {
    .title {
      font-size: 22px;
      font-weight: 600;
      color: #fff;
    }
    .subtitle {
      margin-top: 0px;
      font-size: 14px;
      line-height: 20px;
      padding: 10px;
    }
  }
  .footer {
    width: 480px;
  }
  .modal_df {
    display: flex;
    flex-direction: column;
    .modal_select {
      width: 100%;
    }
  }
  .search {
    background-color: #fcfcfc;
    border-radius: 6px;
    width: 80% !important;
    margin-top: 10px;
  }
  .space_line_search {
    display: none;
  }
 
}