@import "assets/css/_variables.scss";
$rightWidthL: 340px;
$rightWidthS: 300px;

.title {
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
}

.topic_box {
  width: $rightWidthL;
  padding: 20px;

  .topic_item {
    padding: 20px 0;
    font-size: 14px;
    border-bottom: 1px solid #dedede;
  }
  .topic_weight {
      font-weight: 600;
  }
  .mt_f2 {
      margin-top: -2px;
  }
}
.topic_box_fixed {
  position: fixed;
  width: $rightWidthL;
  padding: 0 20px;
  top: 70px;
  bottom: 0;
  overflow: auto;
}

@media (max-width: 1320px) {
  .topic_box {
    width: $rightWidthS;
  }
  .topic_box_fixed {
    width: $rightWidthS;
  }
  
}
@media (max-width: 980px) {
  .topic_box {
    display: none;
  }
}
