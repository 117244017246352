@import "assets/css/_variables.scss";

.page_head_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;

    .title {
        font-size: 36px;
        font-weight: 600;
        color: #fff;
    }
    .subtitle {
        margin-top: 20px;
        font-size: 16px;
        line-height: 26px;
    }
}
.page_body {
    max-width: 1100px;
    margin: 0 auto;

    .page_tab_radio {
        :global {
            .ant-radio-button-wrapper:first-child{
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
            }
            .ant-radio-button-wrapper:last-child{
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
            .ant-radio-button-wrapper {
                background-color: #f4f4f4;
            }
            .ant-radio-button-wrapper:hover {
                color: #000;
            }
            .ant-radio-button-wrapper-checked:hover {
                color: #fff;
            }
            .ant-radio-button-wrapper-checked {
                background-color: #000;
                border-color: transparent;
            }
            .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
                border-right-color: transparent;
            }
            .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
                background-color: #000;
                border-color: transparent;
            }
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                background-color: transparent;
            }
        }

    }
}
.des {
    li{
        list-style: none
    }
}
@media #{$smWidth} {
    .page_head_img {

        .title {
            font-size: 22px;
            font-weight: 600;
            color: #fff;
        }
        .subtitle {
            margin-top: 0px;
            font-size: 14px;
            line-height: 20px;
            padding: 10px;
        }

    }
    .page_body {
        width: 100%;
        .page_tab_radio {
            padding: 0 20px;

        }
    }

}