@import "assets/css/_variables.scss";

.card_cover {
    min-width: 325px;
    height: 325px;
    margin-right: 75px;
    border: 1px solid #dedede;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 4px;
    
    img {
        margin: 0 auto;
        height: 100%;
    }
}

.card_info {
    width: 900px;
    .card_base_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 150px;
        height: 50px;
        margin-right: 10px;
        border-radius: 2px;
    }
    
    .txt_tag {
        font-size: 14px;
    }
    .txt_content {
        font-size: 18px;
    }
    .chainType_img {
        border-radius: 50%;
    }

    .card_base_box_center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 120px;
        height: 50px;
        border-radius: 2px;
    }

    .card_base_item {
        width: 150px;
        height: 50px;
        margin-right: 10px;
    }

    .countDown {
        // span {
            font-size: 18px;
            font-weight: 600;
            background: $multicolour;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        // }
    }
    .btn_buy {
        width: 200px;
        height: 40px;
    }
}
.box_modal {
    text-align: center;
    width: 450px !important;
    :global {
        .ant-modal-header {
            border-bottom: none;
        }
        .ant-modal-body {
            padding: 0 10px 20px 10px;
        }
        .ant-modal-footer {
            border-top: none;
            text-align: center;
            padding-bottom: 30px;
        }
    }
    .pop_img {
      text-align: center;
      img {
        margin: auto;
      }
    }
  }
  @media #{$smWidth} {
      .card_cover {
          width: 80%;
          margin: 0 auto;
      }
      .card_info {
        width: 100%;
        // padding: 0 20px;

        .card_base_box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 120px;
            height: 50px;
            margin-right: 5px;
        }
        .txt_content {
            font-size: 14px;
        }
        .card_base_box_center {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 90px;
            height: 50px;
            border-radius: 2px;
        }
        .card_base_item {
            width: 120px;
            height: 50px;
            margin-right: 5px;
        }
        .card_chain {
            width: 100px;
        } 
        .CountDown {
            span {
                font-size: 14px;
            }
        }
        .btn_buy {
            width: 100%;
            margin-top: 10px;
        }
      }
  }