@import "assets/css/_variables.scss";
.currency_logo {
  border-radius: 50%;
}

.walletModal {
  padding: 0;
  :global {
    .ant-modal-body {
      padding: 0 0 30px;
    }
  }
}
.walletItem {
  cursor: pointer;
  border-bottom: $hr;
  display: flex;
  align-items: center;
  padding: 10px 50px;
  &:hover {
    background-color: #f4f4f4;
  }
}
.walletIcon {
  display: inline-block;
  width: 60px;
  height: 60px;
  border: $hr;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-right: 23px;
  background-color: #fff;
  img {
    width: 100%;
  }
}