@import "assets/css/_variables.scss";

.anniversary {

    .page_head_img {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 100px;
        height: 600px;
        color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        .head_title {
            width: 640px;
        }
        .title {
            font-size: 50px;
            line-height: 70px;
            font-weight: 600;
            color: #fff;
        }
        .subtitle {
            margin-top: 20px;
            font-size: 18px;
            line-height: 26px;
            padding-right: 20px;
        }
        .anniversary_badge {
            width: 560px;
            margin-top: 145px;
            margin-left: 30px;
            animation: fd 2s ease-in-out 2s infinite alternate;
        }
        @keyframes fd {
            from { transform: translate(0,0);}
            to {transform: translate(0,16px)}
        }
    }
    .organizer {
        width: 100%;
        height: 500px;
        background-size: cover;
        .organizer_title {
            width: 80%;
            margin: auto;
            padding-top: 70px;
            font-size: 40px;
            line-height: 56px;
        }
        .swiperList {
            max-width: 1440px;
            margin: auto;
        }
        .slide_img {
            width: 286px;
        }
    }
    .anniversary_body {
        max-width: 1090px;
        padding: 50px 20px 30px;
        margin: auto;
    }
    .intro {
        display: flex;
        .how_box {
            position: relative;
            width: 374px;
            height: 464px;
            border-radius: 20px;
            background: linear-gradient(152.35deg, #161616 5.35%, #410042 98.4%);
        }
        .how_img {
            position: absolute;
            top: 0;
            left: 74px;
            width: 340px;
            z-index: 2;
        }
        .how_pc {
            display: block;
        }
        .how_h5 {
            display: none;
        }
        .how_txt {
            position: absolute;
            top: 80px;
            left: 50px;
            width: 259px;
            height: 300px;
            font-size: 48px;
            line-height: 75px;
            z-index: 3;
            font-weight: 600;
        }
        .intro_plan {
            flex: 1;
            margin-left: 50px;
            .plan_box {
                flex: 1;
                // height: 236px;
                margin-top: 30px;
                padding: 20px 40px;
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.14);
                border-radius: 6px;
            }
            .plan_box:hover {
                box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
                .plan_a {
                    background: linear-gradient(#590067 100%,#87009D 100%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    color: transparent;
                }
            }
            .plan_tag {
                padding: 0 30px;
            }
            .plan_txt {
                font-size: 30px;
                line-height: 34px;
            }
            .plan_a {
                font-size: 90px;
                line-height: 90px;
                font-weight: 600;
            }
            .more_btn {
                margin-top: 20px;
                text-align: right;
            }
        }
    }
    .anniversary_partner {
        width: 1090px;
        margin: 60px auto 0;
        padding-top: 60px;
        padding-bottom: 60px;
        .partner_title {
            width: 100%;
            margin: auto;
            text-align: center;
            font-size: 40px;
            line-height: 56px;
            font-weight: 600;
        }
        .partner_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 240px;
            height: 200px;
            margin: 0 auto 20px auto;
            border: 1px solid #DEDEDE;
            box-sizing: border-box;
            box-shadow: 0px 6px 15px 1px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            background: #fff;
            cursor: pointer;
        }
        .partner_img {
            border-radius: 50%;
            // border: 2px solid #000000;
        }
    
    }
    @media #{$smWidth} {
        .page_head_img {
            flex-direction: column;
            width: 100%;
            height: auto;
            padding: 0 50px;
    
            .head_title {
                width: 100%;
            }
            .title {
                margin-top: 40px;
                font-size: 32px;
                line-height: 40px;
                text-align: center;
            }
            .subtitle {
                text-align: center;
                padding-right: 0;
            }
            .anniversary_badge {
                width: 375px;
                margin-top: 0;
                margin-left: 0;
            }
        }
        .organizer {
            width: 100%;
            height: 400px;
            background-size: contain;
            .organizer_title {
                padding-top: 20px;
                font-size: 40px;
                line-height: 56px;
            }
            .slide_img {
                width: 218px;
            }
        }
        .anniversary_body {
            width: 100%;
            padding: 0;
        }
        .intro {
            flex-direction: column;
            .how_box {
                width: 100%;
                height: 366px;
                border-radius: 0;
            }
            .how_img {
                position: absolute;
                top: 0;
                left: 0px;
                width: 375px;
                z-index: 2;
            }
            .how_pc {
                display: none;
            }
            .how_h5 {
                display: block;
            }
            .how_txt {
                position: absolute;
                top: 70px;
                left: 87px;
                width: 220px;
                height: 224px;
                font-size: 38px;
                line-height: 56px;
                text-align: center;
                font-weight: 600;
                z-index: 3;
            }
            .intro_plan {
                margin-left: 0;
                .plan_box{
                    flex: 1;
                    height: auto;
                    margin-top: 0px;
                    padding-top: 30px;
                    box-shadow: none;
                }
                .more_btn {
                    text-align: left;
                }
            }
        }
        .anniversary_partner {
            width: 100%;
            .partner_title {
                width: 100%;
                font-size: 40px;
                line-height: 56px;
            }
            .partnerList {
                padding: 0 20px;
            }
            .partner_box {
                width: 160px;
                height: 160px;
                margin: 0 auto 10px auto;
            }
        }
    }
}