@import "assets/css/_variables.scss";

.card {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 276px;
  border-radius: 4px;
  border: 2px solid #fff;
  margin: 8px;
  cursor: pointer;
  box-shadow: 0 0 0 1px #dedede;
  background: $multicolour;
  background-origin: border-box;
  // &:hover {
  //   border-color: transparent;
  //   box-shadow: none;
  // }
}
.is_auction {
  border-color: transparent;
  box-shadow: none;
}
.card_box {
  height: 100%;
  padding: 0 8px 8px;
  background-color: #fff;
}
.chain_type {
  display: block;
  position: relative;
  z-index: 1;
  top: -2px;
  left: -10px;
}
.nft_img {
  position: relative;
  margin-top: 5px;
  width: 100%;
  padding-bottom: 100%;
  // background-size: contain;
  // background-position: center;
  // background-repeat: no-repeat;
}
.card_image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
.nft_name {
  margin: 6px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.timer_box {
  position: absolute;
  bottom: 0;
  right: -10px;
  left: -10px;
  background: $multicolour;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  font-size: 14px;
}
.price_box {
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.price_bid {
  font-size: 14px;
}
.price_last {
  color: #9a9a9a;
  font-size: 14px;
}

@media #{$smWidth} {
  .card {
    width: calc(50% - 10px);
    margin: 5px;
    height: auto;
  }
}
