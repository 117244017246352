@import "assets/css/_variables.scss";

.modal_result {
    :global {
       
        .ant-modal-content {
            background: rgba(0, 0, 0, 0.6);
            border: 1px solid #AD59CA;
            backdrop-filter: blur(20px);
            border-radius: 8px;
            color: #fff;
        }
    }
    .modal_main {
        .main_1 {
            flex: 1;
            padding: 6px 30px 6px 10px;
            font-size: 14px;
            line-height: 24px;
            border-right: 1px solid #AC59CA;

            ul {
                padding-left: 14px;
            }
            .tablePc {
                display: block;
            }
            .tableH5 {
                display: none;
            }
        }
        .main_2 {
            flex: 1;
            font-size: 14px;
            padding: 40px 30px 0px;
            line-height: 24px;
        }
    }
}
@media #{$smWidth} {
    .modal_result {
        :global {
            .ant-modal-body {
                padding: 16px;
            }
            .ant-modal-content {
                background: rgba(0, 0, 0, 0.6);
                border: 1px solid #AD59CA;
                backdrop-filter: blur(20px);
                border-radius: 8px;
                color: #fff;
            }
        }
        .modal_main {
            .main_1 {
                padding: 10px 0 10px 10px;
                border-right: none;
                ul {
                    padding-left: 14px;
                }
                .tablePc {
                    display: none;
                }
                .tableH5 {
                    display: block;
                    width: 300px;
                }
            }
            .main_2 {
                flex: 1;
                font-size: 14px;
                padding: 10px 0px 0px 10px;
                line-height: 24px;
            }
        }
    }
}

.kbw {
    background: #000;
    ::-webkit-scrollbar {
        display: none;
        width: 1px;
    }
    .page_head_img {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 100px;
        height: 730px;
        color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        // overflow: hidden;
        .head_main {
            position: relative;
            width: 1100px;
            margin: auto;
            // padding-top: 50px;
        }
        .main_1 {
            margin-bottom: 6px;
            padding: 6px 50px;
            background: linear-gradient(89.98deg, rgba(48, 0, 52, 0.7) 0.01%, rgba(255, 0, 77, 0.7) 22.14%, rgba(48, 0, 52, 0.7) 99.97%);
            box-shadow: inset 0px 4px 40px rgba(255, 4, 185, 0.65);
            border-radius: 40px 40px 6px 6px;
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 70px;
        }
        .main_2 {
            margin-bottom: 6px;
            padding: 40px 50px;
            background: linear-gradient(89.93deg, rgba(0, 0, 0, 0.72) 28.73%, rgba(84, 0, 71, 0.72) 68.46%, rgba(0, 0, 0, 0.72) 90.13%);
            backdrop-filter: blur(22px);
            border-radius: 6px;
            .text {
                font-weight: 600;
                font-size: 60px;
                line-height: 70px;
                text-shadow: 2px 4px 20px rgba(132, 0, 194, 0.8);
            }
            .footballH5 {
               display: none;
            }
        }
        .main_3 {
            padding: 20px 420px 0px 50px;
            background: linear-gradient(89.98deg, rgba(48, 0, 52, 0.7) 0.01%, rgba(255, 0, 77, 0.7) 81.23%, rgba(48, 0, 52, 0.7) 99.97%);
            box-shadow: inset 0px 4px 40px rgba(255, 4, 185, 0.65);
            border-radius:  6px 6px 40px 40px;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 1.3;

            .results {
                width: 251px;
                cursor: pointer;
            }

            .claimNow {
                position: relative;
                display: flex;
                align-items: center;
                margin-left: -20px;
                padding: 5px;
                background-repeat: no-repeat;
                z-index: 3;

                .claim {
                    width: 218px;
                    height: 68px;
                }
            }
        }
        .cup {
            position: absolute;
            top: 20px;
            right: -140px;
            width: 468px;
        }
       
        .ball1 {
            position: absolute;
            bottom: -200px;
            left: 0;
            width: 416px;
            z-index: 2;
        }
    }
    .organizer {
        width: 100%;
        height: 800px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        // background-size: cover;
        // background: linear-gradient(180deg, #1A1837 3.12%, #203660 100%);
        .organizer_title {
            width: 80%;
            margin: auto;
            padding-top: 70px;
            font-size: 40px;
            line-height: 56px;
        }
        .topOne_txt {
            width: 520px;
            font-weight: 600;
            font-size: 86px;
            line-height: 90px;
            background: linear-gradient(302.15deg, #B77946 2.41%, #FFE794 26.39%, #FBDA84 29.38%, #F4C66D 34.38%, #F0B85D 41.37%, #EDB053 48.36%, #EDAE50 56.35%, #EDAF52 66.34%, #EFB65A 72.34%, #F2C168 76.33%, #F6D17B 80.33%, #FBE594 83.32%, #FFF1A3 85.32%, #F6E398 88.32%, #E0BE7C 93.31%, #BD844E 100.3%, #B77946 102.3%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            // text-fill-color: transparent;
        }
        .topOne_img {
            width: 520px;
            img {
                width: 100%;
            }
        }
        .swiperList {
            max-width: 1240px;
            margin: 20px auto 0;
            padding-bottom: 80px;
            :global {
                .swiper-pagination-bullet {
                    width: 170px;
                    height: 6px;
                    border-radius: 10px;
                    background: #55C1BB;
                }
                .swiper-pagination-bullet-active {
                    background: #55C1BB;
                }
            }
        }
        .swiperItem {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        .vs_bg {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 1000px;
            height: 521px;
            margin: 5px;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 20px 38px;
        }
        .slide_img {
            // width: 286px;
            width: 360px;
            // height: 234px;
        }
        .clickallpage {
            position: relative;
            background-repeat: no-repeat;
            width: 184px;
            margin: -70px auto;
            cursor: pointer;
            z-index: 2;
        }
    }
    .kbw_body {
        width: 100%;
        height: 740px;
        background: #000;
        z-index: 1;
        .intro_main {
            position: relative;
            width: 1261px;
            height: 680px;
            margin: auto;
            padding: 100px 40px;
            text-align: center;
            color: #fff;

            .ball2 {
                position: absolute;
                top: -110px;
                right: -80px;
                width: 300px;
            }
            .ball3 {
                position: absolute;
                bottom: -66px;
                left: 0px;
                width: 228px;
            }
        }
        .how {
            // padding-top: 10px;
            font-weight: 600;
            font-size: 50px;
            line-height: 69px;
            color: #fff;
        }
        .step {
            padding: 10px;
            text-align: left;

            .step_title {
                font-weight: 600;
                font-size: 20px;
                line-height: 34px;
            }
            .step_intro {
                font-weight: 300;
                font-size: 14px;
                line-height: 30px;
                text-align: left;
            }.step_time {
                font-weight: 300;
                font-size: 14px;
                line-height: 30px;
                color: #edc8ff;
                text-align: left;
            }
        }
        .intro {
            width: 900px;
            margin: 20px auto;
            font-weight: 300;
            font-size: 20px;
            line-height: 34px;
        }
        .more_btn {
            width: 372px;
            height: 42px;
            line-height: 42px;
            margin: 50px auto;

            :global {
                .ant-btn {
                    background: transparent;
                    color: #fff;
                    border: none;
                    font-size: 20px;
                }
            }
        }
    }
    .fd1 {
        max-width: 1046px;
        margin: auto;
        .ball1 {
            position: absolute;
            top: -90px;
            left: -200px;
            z-index: 2;
            animation: fdball 2s ease-in-out 2s infinite alternate;
        }
        @keyframes fdball {
            from { transform: translate(0,0);}
            to {transform: translate(0,16px)}
        }
        .line1 {
            position: absolute;
            top: -200px;
            right: -190px;
            z-index: 0;
        }
    }
    .partner_footer {
        background-repeat: no-repeat;
        background-size: contain;
    }
    .kbw_partner {
        width: 1090px;
        margin: auto;
        padding-top: 60px;
        padding-bottom: 60px;
        z-index: 2;
        position: relative;
        .partner_title {
            z-index: 2;
            width: 100%;
            margin: auto;
            text-align: center;
            font-size: 40px;
            line-height: 56px;
            font-weight: bold;
            color: #fff;
        }
        .partner_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 240px;
            height: 200px;
            margin: 0 auto 20px auto;
            box-sizing: border-box;
            background: linear-gradient(180deg, #0A0A0A 0%, #201535 100%);
            border: 1px solid #42384A;
            box-shadow: 0px 6px 15px 1px rgba(0, 0, 0, 0.1);
            border-radius: 14px;
            cursor: pointer;
            color: #fff;
        }
        .partner_img {
            border-radius: 50%;
        }
    
    }
    .fd2 {
        max-width: 1046px;
        margin: 0 auto 50px;
        .ball2 {
            position: absolute;
            top: -200px;
            right: -180px;
            z-index: 2;
            animation: fdball 2s ease-in-out 2s infinite alternate;
            @keyframes fdball {
                from { transform: translate(0,0);}
                to {transform: translate(0,16px)}
            }
        }
    }
    @media #{$smWidth} {

        .page_head_img {
            flex-direction: column;
            width: 100%;
            height: auto;
            padding: 30px 20px;

            .head_main {
                width: 100%;
                padding-top: 20px;
            }
            .main_1 {
                padding: 10px 10px 10px 15px;
                font-size: 18px;
                line-height: 24px;
                border-radius: 20px 20px 6px 6px;

            }
            .main_2 {
                position: relative;
                padding: 10px 10px 10px 15px;
                .text {
                    font-weight: 500;
                    font-size: 40px;
                    line-height: 1.3;
                    text-shadow: 2px 4px 20px rgba(132, 0, 194, 0.8);
                }
                .footballH5 {
                    display: block;
                    position: absolute;
                    top: -14px;
                    right: 0;
                }
            }
            .main_3 {
                padding: 10px 10px 10px 15px;
                font-size: 16px;
                line-height: 1.3;
                border-radius: 6px 6px 20px 20px;

                .results {
                    width: 150px;
                }
                .claimNow {
                    margin-left: -14px;
                    .claim {
                        width: 150px;
                        height: 47px;
                    }
                }

            }
            .reward {
                width: 100%;
            }
            .cup {
                display: none;
            }
            .ball1 {
                display: none;
            }
    
            .head_title {
                width: 100%;
            }
        }
        .organizer {
            width: 100%;
            height: auto;
            background-size: cover;
            .organizer_title {
                padding-top: 10px;
                font-size: 36px;
                line-height: 1.3;
            }
            .topOne_txt {
                width: 100%;
                padding: 20px 34px;
                font-size: 36px;
                line-height: 1.3;
                text-align: center;
               
            }
            .topOne_img {
                width: 320px;
            }
            .swiperList {
                
                :global {
                    .swiper-pagination-bullet {
                        width: 100px;
                        height: 6px;
                        border-radius: 10px;
                        background: #55C1BB;
                    }
                    .swiper-pagination-bullet-active {
                        background: #55C1BB;
                    }
                }
            }
            .swiperItem {
                flex-direction: column;
                padding: 0 10px;
            }
            .vs_bg {
                width: 100%;
                height: 185px;
                padding: 0 14px;
                margin: 0;
            }
            .slide_img {
                width: 130px;
                // height: 125px;
            }
        }
        .kbw_body {
            width: 100%;
            height: auto;
            padding: 0;

            .intro_main {
                width: 100%;
                height: auto;
                padding: 100px 20px 20px;
                // transform: rotate(90deg);
                background: #000 !important;
                .ball2 {
                    display: none;
                }
                .ball3 {
                    display: none;
                }
            }
            .how {
                font-size: 36px;
                line-height: 1.3;
            }
            .step {
                padding: 10px;
                text-align: left;
    
                .step_title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 32px;
                }
                .step_intro {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 28px;
                    text-align: left;
                }.step_time {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 28px;
                    color: #edc8ff;
                    text-align: left;
                }
            }
            .how_img {
                display: none;
            }
            .intro {
                width: 100%;
                flex-direction: column;
                padding: 10px;

                .how_box {
                    width: 100%;
                    height: 366px;
                    border-radius: 0;
                }
                
            }
            .more_btn {
                bottom: 20px;
                left: 30px;
            }
        }
        .fd1 {
            display: none;
        }
        .kbw_partner {
            width: 100%;
            padding-top: 20px;
            .partner_title {
                width: 100%;
                font-size: 36px;
                line-height: 56px;
            }
            .partnerList {
                padding: 0 20px;
            }
            .partner_box {
                width: 160px;
                height: 160px;
                margin: 0 auto 10px auto;
            }
           
        }
        .fd2 {
            display: none;
        }
    }
}
:global {
    
  .worldCup_popover {
    .ant-popover-inner  {
      background: rgba(0, 0, 0, 0.6);
      border: 1px solid #AD59CA;
      backdrop-filter: blur(20px);
      border-radius: 8px;
      color: #fff;
    }
    .ant-popover-title {
      padding: 20px 26px 0;
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      border-bottom: none;
    }
    .ant-popover-inner-content {
      width: 380px;
      padding: 6px 26px 10px;
      color: #fff;
      font-size: 16px;
      line-height: 30px;
      font-weight: 300;
    }
  }
  @media #{$smWidth} {
    .worldCup_popover {
        .ant-popover-inner  {
          background: rgba(0, 0, 0, 0.6);
          border: 1px solid #AD59CA;
          backdrop-filter: blur(20px);
          border-radius: 8px;
          color: #fff;
        }
        .ant-popover-title {
          padding: 16px 26px 0;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          border-bottom: none;
        }
        .ant-popover-inner-content {
          width: 280px;
          color: #fff;
          font-size: 14px;
          line-height: 28px;
          font-weight: 300;
        }
      }
  }
}