@import "assets/css/_variables.scss";

:global {
    #root {
        height: 100%;
    }
    .main {
        height: 100%;
    }
}

.claimDfa_module {
    width: 100%;
    min-height: 100%;
    // background: linear-gradient(180deg, rgb(251, 241, 232) 0%, rgb(233, 226, 253) 100%);
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;

    .claimDfa_main {
        max-width: 800px;
        margin: auto;
        padding: 40px 0 100px;
        position: relative;

        .title {
            font-size: 36px;
        }
        .claimDfa_box {
            display: flex;
            height: 500px;
            margin-top: 10px;
            background: #FFFFFF;
            box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
        }
        .box_left {
            width: 320px;
            height: 100%;
            padding: 0 20px;
            // padding-bottom: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #dedede;
        }
        .balance {
            display: flex;
            align-items: center;
        }
        .avaibleTxt {
            font-size: 32px;
        }
        .claim_btn {
            width: 200px;
            height: 50px;
        }
        .box_mt {
            margin-top: 40px;
        }
        .gary_box {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20px;
            font-weight: 600;
            font-size: 20px;
            background: #F5F5F5;
        }
        :global {
            .ant-progress-bg  {
                background: linear-gradient(226.11deg, #5A50D0 0%, #5D4ADD 11.46%, #9F43D7 41.46%, #CF5A7C 66.48%, #EC811E 83.97%, #FFA858 97.92%);
            }
        }
        .progress_percent {
            position: absolute;
        }
        ::-webkit-scrollbar {
            display: none;
        }
        .box_right {
            width: 480px;

            .tab_top {
                padding: 10px;
                align-items: center;
            }

            .multicolour {
                background: linear-gradient(90.5deg, #C4FFFE 9.47%, #E9D3FF 94.73%);
                border-radius: 4px;
                border: none;
            }

            .new_v {
              color: #3D315F;
              font-size: 14px;
            }

            :global {
                .ant-table-thead > tr > th {
                    padding: 2px 16px;
                    font-size: 12px;
                    background: #e5e5e5;
                }
                .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                    width: 0;
                }
                .ant-table-tbody > tr > td {
                    font-size: 14px;
                    border-bottom: 0;
                }
                .ant-empty {
                    height: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                
            
                .rowBgWhite {
                    background: #fff !important;
                }
        
                .rowBgGray {
                    background: #f9f9f9 !important;
                }
            }
        }
        .ball1 {
            position: absolute;
            top: 60px;
            left: -220px;
            animation: fdball 2s ease-in-out 2s infinite alternate;
        }
        .ball2 {
            position: absolute;
            bottom: 60px;
            right: -210px;
            animation: fdball2 2s ease-in-out 3s infinite alternate;
        }
       
        @keyframes fdball {
            from { transform: translate(0,0);}
            to {transform: translate(0,16px)}
        }
        @keyframes fdball2 {
            from { transform: translate(0,0);}
            to {transform: translate(12px,18px)}
        }
    }

}
@media #{$smWidth}  {
    .claimDfa_module {
        overflow: auto;
        padding-bottom: 20px;

        .claimDfa_main {
            padding: 10px;

            .title {
                font-size: 26px;
            }
            .claimDfa_box {
                height: auto;
                flex-direction: column;
            }
            .avaibleTxt {
                font-size: 22px;
            }
            .claim_btn {
                width: 200px;
                height: 40px;
            }
            .box_mt {
                margin-top: 20px;
            }
            .gary_box {
                height: 40px;
                font-size: 16px;
            }
            .box_left {
                width: 100%;
                padding-top: 10px;
                padding-bottom: 20px;
                border-right: 0;
                border-bottom: 1px solid #dedede;
            }
            .box_right {
                width: 100%;

                :global {
                    .ant-empty {
                        height: auto;
                    }
                }
            }
            .ball1, .ball2 {
                display: none;
            }

        }
    }
}