@import "assets/css/_variables.scss";

.dashbord_tip {
  height: 40px;
  line-height: 40px;
  padding-left: 26px;
  color: #6C4622;
  background: #FFF8DE;
  border-radius: 6px;
}
.dashbord_gasFee {
  align-items: center;
  margin-top: 20px;
  padding: 8px 20px;
  background: #F7F3FD;
  border-radius: 4px;
}
.executionAddress {
  text-decoration-line: underline;
  color: #6F4293;
}
.setGasPrice {
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 16px;
  padding: 10px 20px;
  :global {
    .ant-input {
      width: 100;
      border: none;
      background: #fff;
      font-weight: 600;
    }
  }
}
.saveGasPrice {
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 16px;
  padding: 10px 20px;
  border: 1px solid #DEDEDE;
  border-radius: 6px;

  :global {
    .ant-input {
      border: none;
      background: #fff;
      font-weight: 600;
    }
  }
}

.eventDetailPage {
  width: 1280px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;

  .event_baseInfo {
    position: relative;
    display: flex;
    padding: 40px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
  }
  .event_logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
  }
  .baseInfo_name {
    font-weight: 600;
    font-size: 34px;
  }
  .baseInfo_desc {
    width: 860px;
    font-size: 14px;
    line-height: 20px;
  }
  .info_list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .w250 {
      width: 250px;
    }
    .w170 {
      width: 170px;
    }
  }
  .info_item {
    // margin-right: 10px;
    padding: 10px 30px;
    background: #F7F3FD;
    border-radius: 4px;
  }
  .func_box {
    position: absolute;
    top: 25px;
    right: 40px;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .manage_disable {
    opacity: 0.5;
  }
  .publish_btn {
    width: 140px;
    height: 40px;
    margin-right: 6px;
    background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
    color: #fff;
    font-size: 16px;
  }
  .eventManage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 40px;
    // padding: 0 20px;
    background: #F7F3FD;
    border-radius: 6px;
    // background: #FFFFFF;
    // border: 1px solid #DEDEDE;
    // border-radius: 6px;
  }
  .badgeManage {
    margin-top: 20px;
    padding: 30px 40px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
  }
  .badgeList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .badge_item {
      width: 226px;
      margin-right: 12px;
      margin-bottom: 10px;
    }
    .badge_box {
      position: relative;
      width: 220px;
      height: 220px;
      border: 1px solid #DEDEDE;
      border-radius: 6px;
      overflow: hidden;
    }
    .badge_box:hover{
      .badge_func {
        display: flex;
      }
    }
    .badge_img {
      width: 100%;
    }
    .badge_func {
      position: absolute;
      top: 20px;
      left: 19px;
      display: none;
      align-items: center;
      width: 180px;
      height: 40px;
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid #FFFFFF;
      border-radius: 20px;

      .download {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .line_left {
      border-left: 1px solid #DEDEDE;
    }
    .add_box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 225px;
      height: 225px;
      font-size: 48px;
      color: #DEDEDE;
      background: #FFFFFF;
      border: 1px solid #DEDEDE;
      border-radius: 6px;
    }
  }
  .modal_btns {
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-top: 10px;
    padding: 6px 0px;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    .btns_right {
      border-left: 1px solid #DEDEDE;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media #{$smWidth} {

}
