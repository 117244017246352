@import "assets/css/_variables.scss";

.page_main {
  max-width: 1280px;
  margin: 20px auto 0;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
.page_header {
  padding: 40px 20px 40px 60px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}
.df_align_center {
  display: flex;
  align-items: center;
}
.page_content_title {
  line-height: 40px;
  font-size: 36px;
  font-weight: 600;
}
.title_logo {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.des {
  max-width: 800px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}
.pop_claimable {
  position: absolute;
  top: 0px;
  right: 10px;
}
.info_item {
  // margin-right: 10px;
  padding: 10px 30px;
  background: #F7F3FD;
  border-radius: 4px;
}
.w320 {
  width: 320px;
}
.w220 {
  width: 220px;
}


.unclaimedBadgeItem {
  cursor: pointer;
}


.unclaimed_popover {
  display: flex;
  max-width: 420px;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 0 0 10px 0;
}

.unclaimed_img {
  width: 120px;
  height: 120px;
  display: inline-block;
  margin: 10px 6px 0 6px;
  position: relative;
  cursor: pointer;
  border: 2px solid #000;
  border-radius: 20px;
}

.drawer {
  z-index: 999 !important;

  :global(.ant-drawer-body) {
    padding: 0;
  }

  :global(.ant-drawer-content-wrapper) {
    box-shadow: none !important;
  }
}

.badge_section {
  .badge_logo {
    position: relative;
    bottom: 20px;
    float: right;
  }
  .badge_serise {
    margin-top: 20px;
    padding: 30px 40px;
    background: #fff;
    border-radius: 10px;
  }
}
.badgeItem {
  text-align: center;
  padding: 20px 0 20px 0;
  display: inline-block;
  cursor: pointer;
  
  .badge_item_name {
    width: 300px;
    padding: 0 4px;
  }
}

.flex_between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .badgeItem {
    margin-right: 0;
  }
}

.flex_start {
  display: flex;
  flex-wrap: wrap;
}

@media #{$smWidth} {
  .page_main {
    width: calc(100% - 24px);
  }
  .page_header {
    padding: 20px;
  }
  .df_align_center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .page_content_title {
    font-size: 20px;
    text-align: center;
  }
  .pop_claimable {
    display: none;
  }
  .info_item {
    margin: 10px 0 0;
  }
  .w320 {
    width: 100%;
  }
  .w220 {
    width: 100%;
  }
  .title_logo {
    width: 50px;
    height: 50px;
    margin-top: -45px;
  }

  .left_area {
    display: none;
  }
  .badge_section {
    .badge_serise {
      margin-top: 20px;
      padding: 20px;
    }
  }

  .badgeItem {
    text-align: center;
    margin: 0;
    padding: 10px 0;
    // width: 100%;

    .badge_item_name {
      width: 155px;
    }
  }

  .title {
    padding-right: 0;
    margin-right: 0;
  }

  .flex_between {
    display: flex;
    flex-direction: column;
  }

  .flex_start {
    display: flex;
    // flex-direction: column;
  }
}