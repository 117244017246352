@import "assets/css/_variables.scss";

.main {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
.img_box {
  width: 604px;
  padding: 20px 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}
.detail_title {
  margin-bottom: 10px;
}
.cover_module {
  width: 524px;
  height: 524px;
  border: $hr;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.bnt_h5 {
  width: 200px;
}

.like_share {
  position: absolute;
  top: 1px;
  right: 0;
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  border-bottom-left-radius: 4px;
  background: rgba(255, 255, 255, 0.4);
  z-index: 2;

  .like {
    width: 50px;
    text-align: center;
  }

  .share {
    width: 50px;
    text-align: center;
  }
}

.nft_img {
  max-width: 100%;
  max-height: 100%;
}

.detail_des {
  margin-top: 20px;

  :global {
    .ant-tabs-nav-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .ant-tabs-tab {
      padding-left: 10px;
      padding-right: 10px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }

    .ant-tabs-tab:hover {
      color: #000;
      font-weight: 600;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #000;
      font-weight: 600;
    }

    .ant-tabs-ink-bar {
      background: #000;
    }

    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
    .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
      height: 4px;
    }
  }

  .noData {
    height: 100px;

    div {
      max-height: 100px !important;
    }
  }
  .blockProperty {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
  }
}
.nftDetail {
  :global {
    .ant-tabs-nav-operations {
      display: none;
      .ant-tabs-nav-more {
        display: none;
      }
      .anticon svg {
        display: none;
      }
    }
  }
}

.detail_info {
  flex: 1;
  padding: 20px;
  margin-left: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);

  :global {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      width: 100%;
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
      background: #F7F3FD;
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
      background: #fff;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
      flex: 1;
      display: block;
      text-align: center;
      font-size: 16px;
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
      border: 1px solid #dedede;
      border-radius: 10px 10px 0 0;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #000;
      // font-weight: 600;
      font-size: 16px;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
      border-bottom: 1px solid #fff;
      border-radius: 10px 10px 0 0;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 0;
    }

    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-content-holder {
      border: 1px solid #dedede;
      border-top: none;
      border-radius: 0 0 6px 6px;
    }

    .ant-tabs-tabpane {
      height: 641px !important;
    }
   
  }
  .block_table {
    :global {
      .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
      .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        border: 0px solid #dedede;
      }
      .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
      .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-bottom: 0px solid #fff;
      }
      .ant-table-content::-webkit-scrollbar {
        display: none;
      }
    }
    .erc1155_table {
      position: absolute;
      width: 100%;
    }
    :global {
      .ant-table-tbody > tr.ant-table-placeholder > td {
        border: none;
      }
    }
  }


  .block_info {
    padding: 20px 40px;
    border-bottom: 1px solid #dedede;
    overflow-x: auto;
    
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .block_info_2 {
    padding: 20px 40px;
  }
  .block_1155 {
    border-left: 1px solid #dedede;
    padding-left: 30px;
  }

  .block_btns {
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    button {
      span {
        font-size: 18px;
      }
    }
  }

  .block_table {
    margin-top: 40px;
    .table_title {
      width: 100%;
      height: 30px;
      line-height: 30px;
      background: #000;
      color: #fff;
      text-align: center;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    :global {
      .ant-empty-normal {
        margin: 120px 0 0;
      }
      .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
      .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
        padding: 2px 16px;
        background-color: #fff;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-width: 1px 1px 0 1px;
      }
      .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
      .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        background: #EBE3FB;
        color: #6F4293;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        background: #EBE3FB;
        color: #6F4293;
      }

      .ant-table-thead > tr > th,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
        padding: 0px 16px;
        height: 28px;
        line-height: 24px;
        font-size: 12px;
      }
      .ant-table-tbody > tr > td {
        padding: 5px 16px;
        font-size: 14px;
      }
      .ant-tabs-content-holder {
        border: none;
        border-top: 1px solid #dedede;
      }
      .ant-tabs-tabpane {
        height: 384px !important;
        margin-bottom: -1px;
        overflow: auto;
        position: relative;
      }
    }
  }
}
.detail_history {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}
.history_title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 20px;
}
.comment_body {
  height: 100%;

  .comment_main {
    height: 100%;
    display: flex;
    flex-direction: column;
    
  }
  .commentItem {
    padding: 20px 25px 0 20px;
  }
  .comment_content {
    word-break: break-word;
    white-space: pre-wrap;
  }
  .comment_del {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 76px;
    height: 20px;
    margin-top: 4px;
    line-height: 20px;
    border-radius: 2px;
    background: #e8e8e8;
    font-size: 12px;
    color: #000;
    cursor: pointer;
  }
  .comment_sort {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;

    .sort_item:first-child {
      border-right: 1px solid #dedede;
    }
    .sort_item {
      padding: 0px 20px;
      cursor: pointer;
    }
  }
  .comment_list {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    overflow: auto;
  }
  .comment_publish {
    padding: 16px 20px;
    
  }
  .comment_textarea {
    textarea {
      background: #fff;
      resize: none;
    }
    textarea::-webkit-input-placeholder {
      vertical-align: middle;
      line-height: 44px;
    }
    
  }
  :global {
    .ant-input {
      padding: 4px 6px;
    }
    .ant-input-textarea-show-count::after {
      padding-left: 4px;
      font-size: 12px;
    }
  }
  .comment_publish_btn {
    width: 100px;
    height: 40px;
  }
}
.w200_w120 {
  width: 200px;
}
.mr20_mr10 {
  margin-right: 20px;
}

@media #{$smWidth} {
  .detail_title {
    margin-bottom: 0;
  }
  .bnt_h5 {
    width: 45%;
  }
  .w200_w120 {
    width: 120px;
  }
  .mr20_mr10 {
    margin-right: 10px;
  }
  .img_box {
    width: 100%;
  }
  .cover_module {
    // width: 335px;
    // height: 335px;
    margin: 10px auto 0;
    width:100%;
    height:0;
    position: relative;
    padding-bottom: 100%;

    .nft_img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
    }
  }
  .detail_des {
    .blockProperty {
      max-width: 100%;
    }
  }
  .detail_info {
    margin-top: 8px;
    margin-left: 0;

    .block_info,
    .block_info_2 {
      padding: 10px 15px;
    }
    .block_1155 {
      border-left: 1px solid #dedede;
      padding-left: 0px;
    }
    :global {
      .ant-tabs-tabpane {
        height: auto !important;
      }
    }
    .block_table {
      :global {
        .ant-empty-normal {
          margin: 30px 0 ;
        }
      }
    }
  }
  .comment_body {
    max-height: 550px;
    .comment_publish {
      padding: 10px;
    }
    .comment_publish_btn {
      width: 80px;
      height: 36px;
    }
  }
  .detail_history {
    margin-top: 8px;
    :global {
      .ant-table-tbody > tr > td {
        white-space: nowrap;
      }
      .ant-table-content::-webkit-scrollbar {
        display: none;
      }
    }
  }
  
}
