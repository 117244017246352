@import "assets/css/_variables.scss";

.page_content_artist {
    max-width: 1200px;
    margin: auto;
}
.artist_header {
    margin-top: 40px;
    margin-bottom: 40px;
}

.content {
    display: flex;
    flex-wrap: wrap;
}

.creater {
    width: 380px;
    height: 200px;
    padding: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
}

// :global {
//     .infinite-scroll-component  {
//         display: flex;
//         flex-wrap: wrap;
//     }
// }

.artist_item {
    width: 180px;
    height: 200px;
    padding: 10px 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    background: #f9f9f9;
    border-radius: 4px;
}

.item_avatar {
    // position: relative;
    text-align: center;
    width: 52px;
    height: 52px;
    margin: auto;
}

.line {
    width: 1px;
    height: 34px;
    background: #dedede;
}

@media #{$smWidth} {
    .page_content_artist {
        width: 100%;
        margin: auto;
        padding: 20px;
    }
    .artist_header {
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .creater {
        width: 100%;
        margin-right: 0;
    }

    .artist_item {
        width: 162px;
        margin-right: 10px;
    }
}