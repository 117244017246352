@import "assets/css/_variables.scss";

.treasury {
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: contain;

    .treasury_title{
        font-size: 36px;
        line-height: 48px;
        font-weight: 600;
        margin-right: 60px;
        margin-bottom: 20px;
    }
    .nft_show_list {
        display: flex;
        align-items: flex-end;
    }
    .nftBox:first-child {
        margin-right: 14px;
    }

    .nftBox {
        width: 170px;
        height: 220px;
        border-radius: 10px;
        overflow: hidden;
        .nft_show {
            width: 170px;
            height: 170px; 

            video, img {
                width: 100%;
            }
        }
        .nft_info {
            position: relative;
            top: -32px;
            height: 82px;
            padding-top: 10px;
            color: #fff;
            text-align: center;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 38%,#000000 100%);
        }
    }

    .nft_name {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .nft_price {
        height: 40px;
        line-height: 40px;
    }

    .nftBox_no1 {
        width: 210px;
        height: 250px;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 14px;
        .nft_img {
            width: 210px;
            height: 210px;
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
        }
        .nft_info {
            position: relative;
            top: -42px;
            height: 82px;
            padding-top: 10px;
            color: #fff;
            text-align: center;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 40%,#000000 100%);
        }
       
    }

    .treasury_admin {
        width: 730px;
        margin: 50px auto 0;
        border: 1px solid #DEDEDE;
        border-radius: 10px;
        overflow: hidden;

        .treasury_total {
            padding-top: 30px;
            background: #fff;
            text-align: center;
        }
        .total {
            font-size: 50px;
            font-weight: 600;
        }
        .treasury_list {
            background: #f9f9f9;
            padding: 16px 0px 20px;
            border-top: 1px solid #DEDEDE;

            // .li:last-child {
            //     border-left: 1px solid #DEDEDE;
            // }
            .li {
                // flex: 1;
                // display: flex;
                // flex-direction: column;
                width: 50%;
                margin: auto;
                padding: 0 50px;
            }
        }
    }

    .usage {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 930px;
            margin: auto;
        }

    }
}

@media #{$smWidth} {
    .treasury {
        background-size: 200%;
        :global {
            .page_content_h5 {
                padding: 10px;
            }
        }
        .treasury_title{
            width: 266px;
            font-size: 36px;
            line-height: 48px;
            font-weight: 600;
            text-align: center;
            margin: auto;
        }
        .nft_show_list {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .nftBox:first-child {
            margin-right: 6px;
        }
        .nftBox_no1 {
            width: 340px;
            height: 380px;
            margin-right: 0px;
            margin-bottom: 10px;

            .nft_img {
                width: 100%;
                height: 340px;
                background-size: cover;
                background-position: 50%;
                background-repeat: no-repeat;
            }
        }
        .treasury_admin {
            width: 346px;
            .total {
                font-size: 36px;
            }
            .treasury_list {
               
                .li {
                    flex: 1;
                    // display: flex;
                    // flex-direction: column;
                    width: 100%;
                    padding: 0 50px;
                }
            }
        }
        .usage {
            width: 346px;
            img {
                width: 100%;
                margin: auto;
            }
        }
    }
}