@import "assets/css/_variables.scss";

body {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-color: #F5EFFF;
}
.main {
  // max-width: $contentMaxWidth;
  padding-top: $headerHeight;
  margin: 0 auto;
  display: flex;
  min-height: 100%;
}
.content {
  width: 100%;
}
.addMargin {
  margin-left: $sidebarWidth;
}
.addMaxWidth {
  max-width: $contentMaxWidth;
}
.eggLinkImg {
  width: 100%;
  pointer-events: none;
}
.eggLinkA {
  position: absolute;
  display: block;
  width: 71%;
  height: 11%;
  top: 80%;
  left: 15%;
}

.alonePage {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media #{$smWidth} {
  .main {
    padding-top: $headerHeightH5;
    padding-bottom: 70px;
    min-height: 100%;
    height: auto;
  }
  .addMargin {
    margin-left: 0;
  }
}