@import "assets/css/_variables.scss";

.card_box_c {
  width: 25%;
  padding: 10px;
}

.border_default {
  width: 100%;
  padding: 2px;
  border-radius: 10px;
  background: #fff;
  cursor: pointer;
}

.border_activity {
  background: linear-gradient(226.11deg, #5A50D0 0%, #5D4ADD 11.46%, #9F43D7 41.46%, #CF5A7C 66.48%, #EC811E 83.97%, #FFA858 97.92%);
}

.badge_item {
  width: 100%;
  padding-bottom: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .card_chain {
    position: absolute;
    top: 0;
    right: 0;
  }
  .card_type {
    position: absolute;
    top: 12px;
    left: 14px;
  }

  .item_image {
    width: 100%;
    height: 100%;
  }

  .item_footer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1.04%, rgba(0, 0, 0, 0) 38.54%, #000000 81.77%);

    .footer_content {
      position: absolute;
      left: 26px;
      bottom: 28px;
      display: flex;
      align-items: center;
    }
  }

  .item_name {
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }

  .item_activity {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: linear-gradient(180deg, #8E24A8 0%, #CF5A7C 49.48%, #FF8C23 98.44%);
  }

  .activity_pc {
    display: block;
  }

  .activity_h5 {
    display: none;
  }
}

.badge_logo {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

@media #{$smWidth} {
  .card_box_c {
    width: 50%;
    padding: 5px;
  }

  .badge_item {

    .item_footer {
      .footer_content {
        position: absolute;
        left: 14px;
        bottom: 20px;
      }
    }

    .item_name {
      font-size: 12px;
      padding: 0 10px 0 0;
    }

    .activity_pc {
      display: none;
    }

    .activity_h5 {
      display: block;
      font-size: 12px;
      line-height: 16px;
    }

    .badge_logo {
      display: none;
    }
  }
}