@import "assets/css/_variables.scss";

.badge_graph {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .logo {
        position: absolute;
        top: 0;
        padding: 20px 40px;
        cursor: pointer;
    }
    .logo_img {
        height: 30px;
    }
    .spin {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        background-color: rgba(113,62,111,.2);
    }
}
.spin {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        background-color: rgba(113,62,111,.2);
    }
:global{
    .graphin-core {
        background: transparent !important;
    }
}
.badgeTip {
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid #713E6F;
    backdrop-filter: blur(7px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 6px;
}

.info_module {
    position: absolute;
    top: 70px;
    left: -390px;
    height: calc(100% - 70px);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;


    .info_box {
        width: 390px;
        .graph_search {
            width: 100%;
            margin-bottom: 10px;
            background: rgba(0, 0, 0, 0.6);
            border: 1px solid #713E6F;
            border-radius: 50px;
            backdrop-filter: blur(20px);
            overflow: hidden;
        }
        :global {
            .ant-input {
                height: 40px;
                padding-left: 20px;
                background: rgba(0, 0, 0, 0);
                border: none;
                color: #fff;
                font-size: 14px;
            }
            .ant-input-group-addon {
                background: rgba(0, 0, 0, 0);
            }
            .ant-input-group-addon:last-child {
                left: 0;
            }
            .ant-btn {
                border: none;
                background: rgba(0, 0, 0, 0);
            }
            .ant-btn .anticon  {
                color: #fff;
            }

        }
    }

    .info_main {
        width: 100%;
        padding: 24px;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid #713E6F;
        backdrop-filter: blur(20px);
        border-radius: 20px;
    }
    .info_badge {
        width: 280px;
        height: 280px;
        margin: auto;
        img {
            width: 100%;
        }
    }
    .info_title {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
    }
    .holder_avatar {
        width: 20px !important;
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
        }
    }
    .holder_address {
        display: block;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .userInfo_main {
        width: 390px;
        min-height: 680px;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid #713E6F;
        backdrop-filter: blur(20px);
        border-radius: 20px;
        .info {
            padding: 24px;
            text-align: center;

            .avatar {
                width: 120px;
                height: 120px;
                border: 2px solid #fff;
                border-radius: 50%;
            }
            .user_name {
                margin-top: 20px;
                font-size: 20px;
                font-weight: 600;
            }
            .user_handleName {
                margin-top: 6px;
                margin-bottom: 20px;
                font-size: 14px;
                color: #D9CEEE;
            }
            .user_item {
                margin-bottom: 30px;
                font-size: 14px;
            }
            .line {
                width: 100%;
                height: 1px;
                background-color: #713E6F;
            }
            .user_des {
                margin: 30px 0;
                padding: 0 20px;
                text-align: left;
            }
        }
    }
    .checkAll {
        margin-top: 10px;
        font-size: 14px;
        line-height: 22px;
        color: #CB89FF;
    }
    .control_drawer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 200px;
        margin-left: 20px;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid #713E6F;
        backdrop-filter: blur(20px);
        border-radius: 50px;
        cursor: pointer;
    }
    .triangle {
        transform: rotate(180deg);
    }
}
.info_open{
    left: -390px;
    animation: openDrawer 1s linear 1;
    animation-fill-mode: forwards;
}
.info_close{
    left: 30px;
    animation: closeDrawer 1s linear 1;
    animation-fill-mode: forwards;
}
@keyframes openDrawer {
    form {left:-390px}
    to {left: 30px}
}
@keyframes closeDrawer {
    form {left:30px}
    to {left: -390px}
}
.btn_footer {
    position: absolute;
    left: 0;
    bottom: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 8;

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 40px;
        margin: 0 10px;
        border: 1px solid #713E6F;
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 50px;
    }
}
@media #{$smWidth} {
    .badge_graph {
        .logo {
            padding: 10px 20px;
        }
        .logo_img {
            height: 20px;
        }
    }
    .info_module {
        top: 50px;
        left: -300px;
        height: calc(100% - 50px);
        z-index: 9;
        .info_box{
            width: 300px;
            
        }
        .info_badge {
            width: 120px;
            height: 120px;
            margin: auto;
        }
        .info_main {
            max-height: 500px;
            overflow: auto;
        }
        .userInfo_main {
            width: 300px;
            min-height: 500px;
            .info {
                padding: 16px;
                text-align: center;
                .avatar {
                    width: 100px;
                    height: 100px;
                    border: 2px solid #fff;
                    border-radius: 50%;
                }
                .user_name {
                    font-size: 18px;
                }
                .user_handleName {
                    margin-top: 6px;
                    margin-bottom: 20px;
                    font-size: 14px;
                    color: #D9CEEE;
                }
                .user_item {
                    margin-bottom: 20px;
                    font-size: 14px;
                }
                .line {
                    width: 100%;
                    height: 1px;
                    background-color: #713E6F;
                }
                .user_des {
                    margin: 30px 0;
                    padding: 0 20px;
                    text-align: left;
                }
            }
        }
    }
    .info_open{
        left: -300px;
        animation: openDrawer 1s linear 1;
        animation-fill-mode: forwards;
    }
    .info_close{
        left: 20px;
        animation: closeDrawer 1s linear 1;
        animation-fill-mode: forwards;
    }
    @keyframes openDrawer {
        form {left:-300px}
        to {left: 20px}
    }
    @keyframes closeDrawer {
        form {left:20px}
        to {left: -300px}
    }
}