@import "assets/css/_variables.scss";

.card_main {
    width: 220px;
    margin: 0 auto 20px auto;
}
.card_box {
    position: relative;
    border: 2px solid #000;
    border-radius: 4px;
    box-shadow: 8px 8px 0px 0px black;
    overflow: hidden;
    
}
.card_img_bg {
    position: relative;
    width: 100%;
    padding-bottom: 125%;
    background-size: cover;
}
.card_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.card_title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}
.card_detail {
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #fff;
    text-align: center;
    border: 1px solid #000;
    box-shadow: 4px 4px 0px 0px black;
    font-weight: 600;
    font-size: 18px;
  }

@media #{$smWidth} {
    .card_main {
        width: 100%;
        // margin: 0 4% 15px 0;
    }
    .card_box {
        box-shadow: 4px 4px 0px 0px black;
    }
}