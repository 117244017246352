@import "assets/css/_variables.scss";

.badgeManage {
  width: 1280px;
  margin: 40px auto;
  
  :global{
    .ant-radio-group {
      height: 32px;
      border-radius: 46px;
      background: #D9CEEE;
      overflow: hidden;
    }
    .ant-radio-button-wrapper {
      width: 100px;
      padding: 0;
      text-align: center;
      border: none;
      font-size: 14px;
      background: #D9CEEE;
      color: #845DA4;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      content: none;
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: #845DA4;
      border: 1px solid #fff;
      border-radius: 46px;
    }
  }
  .create_box_btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 170px;
    margin-top: 20px;
    border: 1px solid #C2ABD7;
    border-radius: 20px;
    background: #FFFFFF;
    color: #51356B;
  }
  .create_btn {
    width: 200px;
    height: 50px;
    background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
    color: #fff;
    font-size: 16px;
  }
  .create_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
    background: #FFFFFF;
    border: 1px solid #C2ABD7;
    border-radius: 10px;
  }
  .badge_card {
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;

    .card_bg {
      position: relative;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1.04%, rgba(0, 0, 0, 0) 38.54%, #000000 81.77%);
    }
    .card_footer {
      position: absolute;
      left: 24px;
      bottom: 28px;
      display: flex;
      align-items: center;
    }
    .card_logo {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .showFlow {
    margin-top: 48px;
    text-align: center;
    color: #51356B;
    cursor: pointer;
  }
}
.create_btn {
  width: 200px;
  height: 50px;
  background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%) !important;
  color: #fff;
  font-size: 16px;
}

.createBadge {
  width: 1280px;
  margin: 40px auto;
  .steps {
    width: 400px;

    :global {
      .ant-steps-item-icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin: 0;
        font-weight: 600;
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background: #6F4293;
      }
      .ant-steps-item-process .ant-steps-item-icon {
        border-color: #6F4293;
      }
      .ant-steps-item-wait .ant-steps-item-icon {
        border-color: #6F4293;
      }
      .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
        color: #6F4293;
      }
      .ant-steps-item {
        padding-left: 0;
      }
      .ant-steps-item-title {
        padding-left: 0;
        padding-right: 0;
      }
      .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background:#fff;
        border: 1px dashed #6F4293;
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
        background:#fff;
        border: 1px dashed #6F4293;
      }
      .ant-steps-item-title::after{
        top: 20px;
      }
      .ant-steps-item-finish .ant-steps-item-icon{
        border-color: transparent;
        background: #EBE3FB;
      }
      .anticon {
        svg {
          color: #0FB800;
        }
      }
      .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
        background:#fff;
        border: 1px dashed #6F4293;
      }
    }
  }

  .step_box {
    display: none;
    width: 100%;
    margin-top: 20px;
    background: #fff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;

    .box_header {
      padding: 10px 20px;
      color: #fff;
      font-size: 20px;
      background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
    }
    .box_content {
      padding: 30px 38px;
    }
    .subtitle {
      height: 44px;
      color: #51356B;
      font-size: 12px;
    }
    .event_logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 1px solid #DEDEDE;
      background: #F9F9F9;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .event_cover {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      border: 1px solid #DEDEDE;
      background: #F9F9F9;
      background-repeat: no-repeat;
      background-size: cover;
    }
    :global {
      .ant-form-item .ant-mentions, .ant-form-item textarea.ant-input {
        padding: 10px 16px;
      }
      .ant-form-item .ant-input-textarea-show-count::after {
        position: absolute;
        bottom: 30px;
        right: 14px;
      }
      .ant-picker {
        width: 100%;
        border-radius: 6px;
        background-color: #f9f9f9;
      }
      .ant-picker:hover, .ant-picker-focused {
        background-color: #fff;
      }
    }
    .option_img {
      display: inline-block;
      height: 22px;
      border-radius: 50%;
    }
    .option_name {
      padding-left: 8px;
    }
    
    .preview {
      font-size: 16px;
      line-height: 24px;
      color: #6F4293;
      text-decoration-line: underline;
    }
    .selectType_box {
        width: 392px;
      .selectType {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 390px;
        height: 390px;
        // margin-right: 14px;
        border: 1px solid #EBE3FB;
        border-radius: 6px;
        cursor: pointer;

        .selectType_img {
          width: 340px;
        }
        .selectType_soon {
          color: rgba(111, 66, 147, 0.4);
        }
      }
      .selectType_active {
        border-color: #6F4293;
      }
      .selectType_des {
        margin-top: 30px;
        padding-right: 10px;
        color: #51356B;
      }
    }
    .select_eligibility {
      width: 500px;
    }
    .allUser {
      width: 1200px;
      height: 420px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #F9F9F9;
      border-radius: 6px;
    }
    .download_temp {
      color: #6F4293;
      text-decoration-line: underline;
    }
    .filter_box {
      padding: 10px 20px;
      background: #F9F9F9;
      :global {
        .ant-tabs {
          overflow: visible;
        }
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
          overflow: visible;
        }
        .ant-tabs-tab {
          flex: 1;
          display: flex;
          justify-content: center;
          margin-left: 2px !important;
          border: none;
        }
        .ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
          margin: 10px;
        }
        .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before {
          border-bottom: none;
        }
        .ant-tabs-nav-list {
          width: 100%;
        }
        .ant-tabs-tab-active {
          margin-bottom: -10px;
          border-radius: 6px 6px 0 0;        
          box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
        }
        .ant-tabs-tabpane {
          padding: 18px;
          border-radius: 6px;
          background: #fff;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
        
      }
      .add_list {
        display: flex;
        flex-wrap: wrap;
      }
      .add_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 364px;
        height: 40px;
        margin: 0 10px 12px 0;
        padding: 8px 12px;
        background: #F9F9F9;
        border: 1px solid #DEDEDE;
        border-radius: 6px;
      }
      .add_btn {
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
      .task_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        margin-bottom: 10px;
        background: #FFFFFF;
        border: 1px solid #DEDEDE;
        border-radius: 6px;
        overflow: hidden;
      }
      .task_name {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        width: 200px;
        height: 100%;
        padding-left: 15px;
      }
      .task_blue {
        background: #EBF5FF;
        color: #508FD9;
      }
      .task_blue2 {
        background: #E6E8FF;
        color: #5066D9;
      }
      .task_purple {
        background: #F2EBFF;
        color: #6F4293;
      }
      .task_content {
        padding-left: 200px;
      }
      :global {
        .ant-radio-button-wrapper {
          width: 120px;
          text-align: center;
        }
        .ant-radio-button-wrapper:first-child {
          border-radius: 6px 0 0 6px;
        }
        .ant-radio-button-wrapper:last-child {
          border-radius: 0 6px 6px 0;
        }
        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          background: #508FD9;
          border-color: #508FD9;
        }
      }
    }
    .eventInfo_list {
      display: flex;

      .eventInfo_item {
        padding-right: 30px;
        font-size: 18px;

        .item_content {
          margin-top: 8px;
          height: 56px;
          display: flex;
          align-items: center;
        }
      }
      .line_left {
        padding-left: 50px;
        padding-right: 50px;
        border-left: 1px dashed #DEDEDE;
      }
    }
    .text_tip {
      font-size: 14px;
      color: #51356B;
    }
    .cover_preview {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 320px;
      margin: 10px 0 20px;
      padding: 20px;
      background: #F9F9F9;
      border: 1px solid #DEDEDE;
      border-radius: 4px;

      .cover {
        width: 284px;
        height: 284px;
        background: #E2E2E2;
        border: 1px solid #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .publishInfo_item {
      flex: 1;
      margin-bottom: 10px;
      padding: 10px 40px;
      background: #F7F3FD;
      border-radius: 4px;
    }
    .publish_badges {
      width: 100%;
      background: #F9F9F9;
      border: 1px solid #DEDEDE;
      border-radius: 6px;
      overflow: hidden;

      .table_title {
        display: flex;
        padding: 6px 40px;
        background: #EBE3FB;
        border-radius: 6px 6px 0px 0px;
      }
      .table_group {
        padding: 6px 40px;
        background: #F7F3FD;
        color: #6F4293;;
      }
      .table_item {
        display: flex;
        align-items: center;
        padding: 20px 40px;
      }
      .item_img {
        width: 60px;
        min-width: 60px;
        height: 60px;
        border: 1px solid #DEDEDE;
        border-radius: 4px;
        overflow: hidden; 
      }
      .bg_white {
        background: #fff;
      }
      .bg_gray {
        background: #F9F9F9;
      }
    }
    .noFinished {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(30px);
    }
    .ft_purple {
      color: #6F4293;
    }
    .blindBox_info {
      display: flex;
      padding-top: 20px;
      .blindBox_cover {
        width: 258px;
        height: 258px;
        border-radius: 4px;
      }

    }
    .btn_success {
      border-color: #50B83F;
      color: #50B83F;
    }
    .publish_contract {
      display: flex;

      :global {
        .ant-steps {
          width: auto;
        }
      }
      .steps_vertical {
    
        :global {
          .ant-steps {
            width: auto;
          }
          .ant-steps-item-icon {
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin: 0;
            font-weight: 600;
          }
          .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
            background: #229C1F;
          }
          .ant-steps-item-process .ant-steps-item-icon {
            border-color: #229C1F;
          }
          .ant-steps-item-wait .ant-steps-item-icon {
            border-color: #229C1F;
          }
          .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
            color: #229C1F;
          }
          .ant-steps-item {
            padding-left: 0;
          }
          .ant-steps-item-title {
            padding-left: 0;
            padding-right: 0;
          }
          .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
            background:#fff;
            border: 1px dashed #50b83f;
          }
          .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
            background:#fff;
            border: 1px dashed #50b83f;
          }
          .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
            background:#fff;
            border: 1px dashed #50b83f;
          }
          .ant-steps-item-title::after{
            top: 20px;
          }
          .ant-steps-item-finish .ant-steps-item-icon{
            border-color: transparent;
            background: #dcf1d9;
          }
          .anticon {
            svg {
              color: #0FB800;
            }
          }
          .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
            background:#fff;
            border: 1px dashed #229C1F;
          }
          .ant-steps-item:last-child {
            flex: none;
          }
        }
      }
    }
    .airdrop_step3 {
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-right: 26px;
      text-align: center;
      line-height: 40px;
      border: 1px solid #50B83F;
      border-radius: 50%;
      color: #50B83F;
      font-weight: 600;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'
    }
    .transfer_tip {
      padding: 16px 30px;
      background: #FFF8DE;
      border: 1px solid #EFE5C0;
      border-radius: 6px;
    }
    .airdrop_address {
      padding: 12px 20px;
      border: 1px solid #DEDEDE;
      border-radius: 6px;     
    }

  }
  
  .step_box_show {
    display: block !important;
  }
  .step_box_hidden {
    display: none !important;
  }
  :global {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #d9d9d9;
      background: #f9f9f9;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}

:global {
  .event_popover {
    .event_item {
      width: 300px;
      height: 300px;
      margin-bottom: 10px;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
  
      .card_chain {
        position: absolute;
        top: 0;
        right: 0;
      }
  
      .item_image {
        width: 100%;
        height: 100%;
      }
      .item_footer {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1.04%, rgba(0, 0, 0, 0) 38.54%, #000000 81.77%);
        .footer_content {
          position: absolute;
          left: 26px;
          bottom: 28px;
          display: flex;
          align-items: center;
        }
        .badge_logo {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
      .item_name {
        display: flex;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
      }
    }
  }
  .ql-container.ql-snow {
    min-height: 300px;
    max-height: 500px;
    overflow: auto;
  }
}
@media #{$smWidth} {

}
