@import "assets/css/_variables.scss";

.card {
  padding: 20px;
}
.icon {
  width: 18px;
}
.add_border {
  border-bottom: $hr;
}
.content_box {
  margin: 10px 50px;
}
.imgBox {
  margin-top: 10px;
  min-height: 100px;
  max-height: 600px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.comment_nftCard {
  position: relative;
  display: flex;
  width: 100%;
  height: 154px;
  margin-top: 6px;
  padding: 10px;
  border: 1px solid #D4D4D4;
  border-radius: 4px;

  .nft_cover {
    width: 133px;
    height: 133px;
    border-radius: 4px;
  }

  .nft_tag {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 45px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    background: linear-gradient(97.39deg, #484848 -0.2%, #606060 20.15%, #888888 32.15%, #808080 47.29%, #333333 100%);
    border: 1px solid #575757;
    border-radius: 0px 4px 0px 9px;
  }
  .nft_info {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
    display: flex;
    flex-direction: column;
  }
  .nft_des {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 19px;
  }
}
.nftTag {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 8;
  padding: 4px 20px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
}
.cardImage {
  width: 100%;
}

.bottom_box {
  margin: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cell_module {
  display: flex;
  align-items: center;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  width: 60px;
}

.permission_tag {
  padding: 1px 10px;
  margin-left: 10px;
  background-color: #f2f2f2;
  color: #aaa;
  font-size: 12px;
  border-radius: 4px;
  display: inline-block;
}
.card_more_box {
  :global {
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
    }
  }
}

@media #{$smWidth} {
  .content_box {
    margin: 10px;
  }
  .bottom_box {
    margin: 0 10px;
  }
  .comment_nftCard {
    .nft_info {
      padding-left: 10px;
    }
  }
}
