@charset "utf-8";
/**
 * @include ACSS(font-size; fs; 12, 16; 1px);
 * @prop: 属性名
 * @name: 缩写名
 * @list: 值列表
 * @unit: 单位换算表达式, 默认为1px, 想要 rem 填写 1/16*1rem
 */
@mixin SACSS($prop: false, $name: "", $list: (), $unit: false) {
  @each $item in $list {
    .#{$name}#{$item } {
      #{$prop}: if($unit, $item * $unit, $item);
    }
  }
}

/**
 * @include Margins((8,16), 1px);
 * @list: 值列表
 * @unit: 单位换算表达式, 默认为1px, 想要 rem 填写 1/16*1rem
 */
@mixin Margins($list, $unit) {
  @include SACSS(margin, m, $list, $unit);
  @include SACSS(margin-top, mt, $list, $unit);
  @include SACSS(margin-bottom, mb, $list, $unit);
  @include SACSS(margin-right, mr, $list, $unit);
  @include SACSS(margin-left, ml, $list, $unit);
}

/**
 * @include Paddings((8,16), 1px);
 * @list: 值列表
 * @unit: 单位换算表达式, 默认为1px, 想要 rem 填写 1/16*1rem
 */
@mixin Paddings($list, $unit) {
  @include SACSS(padding, p, $list, $unit);
  @include SACSS(padding-top, pt, $list, $unit);
  @include SACSS(padding-right, pr, $list, $unit);
  @include SACSS(padding-bottom, pb, $list, $unit);
  @include SACSS(padding-left, pl, $list, $unit);
}

/*
* 以下属性因为太常用所有放在了这里
* 这里的命名并没有严格按照上面的规则
*/

/* 清除浮动 */
@mixin Clearfix() {
  &:after {
    display: table;
    content: "";
    clear: both;
  }
}
/* 文字超出一行点点点 */
@mixin Ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 多行文本点点点 */

@mixin Ellipsis-multiple() {
  display: -webkit-box;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
}
