@import "assets/css/_variables.scss";

.nft_modal {
    text-align: center;
    width: 500px !important;
    :global {
        .ant-modal-header {
            .ant-modal-title {
                font-size: 18px;
            }
        }
        .ant-modal-body {
            padding: 0 10px 20px 10px;
            height: 500px;
            overflow: auto;
        }
        ::-webkit-scrollbar {
            width: 2px;
            height: 10px;
        }
        .ant-modal-footer {
            border-top: none;
            text-align: center;
            padding-bottom: 25px;
        }
    }
    
    .list_nftHeader {
        display: flex;
        // justify-content: center;
        flex-wrap: wrap;
        
    }
    .item_nftHeader {
        width: 148px;
        height: 148px;
        margin-top: 16px;
        margin-left: 4px;
        margin-right: 4px;
        border: 1px solid #DEDEDE;
        border-radius: 4px;
        position: relative;
        margin-top: 5px;
        // width: 100%;
        // padding-bottom: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        .img_box {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }

        .item_img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.profile {
    box-sizing: border-box;
    background-color: #fff;
    margin-top: 30px;
    padding: 30px 60px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.profile_avatar {
    display: flex;
    align-items: flex-end;
}

.avatar_perview {
    width: 260px;
    height: 260px;
    border-radius: 50%;
    border: 1px solid #dedede;
    background-color: #f9f9f9;
    overflow: hidden;

    div {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

    }
}
.profile_banner_module {
    display: flex;
}
.profile_banner {
    width: 800px;
    height: 170px;
    background: #f9f9f9;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 9;
    }
    &:hover {
        .uploadBtn {
            z-index: 9;
        }
    }
}
.profile_banner_tip {
    flex: 1;
    background: #dedede;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding: 20px;
}
.profile .set_btn {
    font-weight: 600;
    font-size: 16px;
    background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%)
}

@media #{$smWidth} {
    .profile {
        margin-top: 0;
        padding: 20px;
    }
    .profile_banner_module {
        flex-direction: column;
    }
    .avatar_perview {
        width: 180px;
        height: 180px;
    }
    .profile_banner {
        width: 100%;
        height: 160px;
    }
    .set_btn {
      width: 100%;
    }
}
