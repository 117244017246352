@import "assets/css/_variables.scss";

.kbw {

  // padding: 0 0 200px;
  .page_head_img {
    position: relative;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding: 0 100px;
    height: 600px;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;

    .title {
      width: 880px;
      margin-top: 70px;
      display: flex;
      text-align: center;
      justify-content: center;
      // align-items: flex-end;
      font-size: 60px;
      line-height: 70px;
      font-weight: 600;
      color: #fff;
      text-shadow: 3px 4px 0px rgba(78, 155, 211, 0.63);

      .title_img {
        display: flex;
        align-items: flex-end;
        margin-bottom: 0px;
      }

      .title_1 {
        // margin-right: -8px;
        z-index: 2;
      }

      .title_2 {
        margin-left: -8px;
        z-index: 2;
      }
    }
    .title_h1 {
      width: 90%;
    }

    .subtitle {
      // width: 620px;
      margin-top: 20px;
      font-size: 22px;
      line-height: 32px;
      padding-right: 20px;
    }

    .bat1, .bat2 {
      animation: fdball 2s ease-in-out 2s infinite alternate;
    }
    .bat1 {
      width: 123px;
      position: absolute;
      left: 4.5%;
      bottom: 106px;
    }
    .bat2 {
      width: 165px;
      position: absolute;
      right: 3%;
      bottom: -100px;
    }
  }

  .organizer {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;

    .bg_cloud {
      position: absolute;
      z-index: -1;
      height: 100%;
      width: 120%;
      top: 0;
      animation: cloud 20s linear;
      animation-fill-mode: forwards;
    }

    @keyframes cloud {
      from {
        left: -20%;
      }

      to {
        left: 0;
      }
    }

    .organizer_title {
      width: 80%;
      margin: auto;
      padding-top: 70px;
      font-size: 40px;
      line-height: 56px;
    }

    .swiperList {
      max-width: 1440px;
      margin: auto;
    }

    .slide_img {
      width: 286px;
    }
  }

  .kbw_body {
    max-width: 1046px;
    margin: 80px auto 0;
    background: linear-gradient(125.61deg, #D45F43 4.23%, #410042 77.53%);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    position: relative;
    z-index: 1;

    .how {
      padding: 40px 60px;
      font-weight: 600;
      font-size: 50px;
      line-height: 69px;
      color: #fff;
    }

    .intro {
      padding: 30px 50px 74px;
      background: #fff;
      border-radius: 20px;
      position: relative;
      z-index: 2;

      .intro_main {
        width: 75%;
        font-size: 16px;
        line-height: 24px;
      }

      .more_btn {
        position: absolute;
        z-index: 99;
        // margin-top: 10px;
        // bottom: 0px;
        // left: 50px;
      }
    }

    .how_img {
      position: absolute;
      top: -50px;
      right: 0;
      z-index: 2;
    }

    .more_btn {
      position: absolute;
      z-index: 99;
      // margin-top: 10px;
      bottom: 30px;
      left: 50px;
    }
  }

  .fd1 {
    max-width: 1046px;
    margin: auto;

    .ball1 {
      position: absolute;
      top: -120px;
      left: -200px;
      z-index: 2;
      animation: fdball 2s ease-in-out 2s infinite alternate;
    }

    @keyframes fdball {
      from {
        transform: translate(0, 0);
      }

      to {
        transform: translate(0, 16px)
      }
    }

    .line1 {
      position: absolute;
      top: -200px;
      right: -190px;
      z-index: 0;
    }
  }

  .kbw_partner {
    width: 1090px;
    margin: 60px auto 0;
    padding-top: 60px;
    padding-bottom: 60px;
    z-index: 2;
    position: relative;

    .partner_title {
      z-index: 2;
      width: 100%;
      margin: auto;
      text-align: center;
      font-size: 40px;
      line-height: 56px;
      font-weight: 600;
    }

    .partner_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 240px;
      height: 200px;
      margin: 0 auto 20px auto;
      border: 1px solid #DEDEDE;
      box-sizing: border-box;
      box-shadow: 0px 6px 15px 1px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      background: #fff;
      cursor: pointer;
    }

    .partner_img {
      border-radius: 50%;
    }

  }

  .fd2 {
    max-width: 1046px;
    margin: 0 auto 50px;

    .ball2 {
      position: absolute;
      top: -0px;
      right: -180px;
      z-index: 22;
      animation: fdball 2s ease-in-out 2s infinite alternate;

      @keyframes fdball {
        from {
          transform: translate(0, 0);
        }

        to {
          transform: translate(0, 16px)
        }
      }
    }

    .line2 {
      position: absolute;
      top: -300px;
      left: -180px;
    }
  }

  @media #{$smWidth} {

    .page_head_img {
      width: 100%;
      height: 300px;
      padding: 0 30px;

      .head_title {
        width: 100%;
      }

      .title {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-top: 40px;
        font-size: 32px;
        line-height: 28px;

        .title_img {
          margin-bottom: 10px;
        }

        .title_1 {
          margin-right: 0px;
          z-index: 2;
        }

        .title_2 {
          margin-left: 0px;
          z-index: 2;
        }
      }

      .title_h1 {
        width: 100%;
      }

      .x {
        width: 40px;
      }

      .subtitle {
        font-size: 14px;
        line-height: 1.2;
        width: 100%;
        text-align: center;
        padding-right: 0;
      }

      .kbw_badge {
        width: 375px;
        margin-top: 0;
        margin-left: 0;
      }

      .bat1 {
        width: 60px;
      }
      .bat2 {
        width: 80px;
        bottom: -50px;
      }

    }

    .organizer {
      width: 100%;
      height: 400px;
      background-size: contain;

      .organizer_title {
        padding-top: 20px;
        font-size: 30px;
        line-height: 56px;
      }

      .slide_img {
        width: 218px;
      }

      .bg_cloud {
        position: absolute;
        z-index: -1;
        height: 100%;
        width: auto;
        top: 0;
        animation: cloud 40s linear;
        animation-fill-mode: forwards;
      }
  
      @keyframes cloud {
        from {
          left: -100%;
        }
  
        to {
          left: 0;
        }
      }
    }

    .kbw_body {
      width: 100%;
      padding: 0;

      .how {
        font-size: 28px;
        line-height: 1.3;
      }

      .how_img {
        display: none;
      }

      .intro {
        flex-direction: column;
        padding: 20px 30px 64px;

        .how_box {
          width: 100%;
          height: 366px;
          border-radius: 0;
        }

        .intro_main {
          width: 100%;
        }
      }

      .more_btn {
        bottom: 20px;
        left: 30px;
      }
    }

    .fd1 {
      display: none;
    }

    .kbw_partner {
      width: 100%;

      .partner_title {
        width: 100%;
        font-size: 40px;
        line-height: 56px;
      }

      .partnerList {
        padding: 0 20px;
      }

      .partner_box {
        width: 160px;
        height: 160px;
        margin: 0 auto 10px auto;
      }
    }

    .fd2 {
      display: none;
    }
  }
}