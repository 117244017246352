@import "assets/css/_variables.scss";
$emoji-base-color: #ffda6a;
$emoji-like-color: #548dff;
$emoji-love-color: #f55064;
$emoji-angry-color: #d5234c;
$emoji-white-color: #ffffff;
$emoji-black-color: #000000;
$emoji-text-color: #8a8a8a;

.badge_modal {
    text-align: center;
    width: 450px !important;
    :global {
        .ant-modal-header {
            border-bottom: none;
            .ant-modal-title {
                font-size: 20px;
            }
        }
        .ant-modal-body {
            padding: 0 10px 20px 10px;
        }
        .ant-modal-footer {
            border-top: none;
            text-align: center;
            padding-bottom: 30px;
        }
    }
    .emoji {
        width: 120px;
        height: 120px;
        margin: 15px 15px 40px;
        background: $emoji-base-color;
        display: inline-block;
        border-radius: 50%;
        position: relative;
      
        &:after {
          position: absolute;
          bottom: -40px;
          font-size: 18px;
          width: 60px;
          left: calc(50% - 30px);
          color: $emoji-text-color;
        }
      }
      
      .emoji__face,
      .emoji__eyebrows,
      .emoji__eyes,
      .emoji__mouth{
        position: absolute;
      
        &:before,
        &:after {
          position: absolute;
          content: "";
        }
      }
      
      .emoji__face {
        width: inherit;
        height: inherit;
      }
      
      .emoji--yay {
        &:after {
          /*content: 'Yay';*/
          animation: yay-reverse 1s linear infinite;
        }
      
        .emoji__face {
          animation: yay 1s linear infinite alternate;
        }
      
        .emoji__eyebrows {
          left: calc(50% - 3px);
          top: 30px;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background: transparent;
          box-shadow: -6px 0 0 0 $emoji-black-color, -36px 0 0 0px $emoji-black-color, 6px 0 0 0 $emoji-black-color,
            36px 0 0 0px $emoji-black-color;
      
          &:before,
          &:after {
            width: 36px;
            height: 18px;
            border-radius: 60px 60px 0 0;
            background: transparent;
            border: 6px solid black;
            box-sizing: border-box;
            border-bottom: 0;
            bottom: 3px;
            left: calc(50% - 18px);
          }
      
          &:before {
            margin-left: -21px;
          }
      
          &:after {
            margin-left: 21px;
          }
        }
      
        .emoji__mouth {
          top: 60px;
          background: transparent;
          left: 50%;
      
          &:after {
            width: 80px;
            height: 80px;
            left: calc(50% - 40px);
            top: -75px;
            border-radius: 50%;
            background: transparent;
            border: 6px solid $emoji-black-color;
            box-sizing: border-box;
            border-top-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
            z-index: 1;
          }
      
          &:before {
            width: 6px;
            height: 6px;
            background: transparent;
            border-radius: 50%;
            bottom: 5px;
            left: calc(50% - 3px);
            box-shadow: -25px 0 0 0 $emoji-black-color, 25px 0 0 0 $emoji-black-color, -35px -2px 30px 10px $emoji-angry-color,
              35px -2px 30px 10px $emoji-angry-color;
          }
        }
      }
      
      @keyframes yay {
        25% {
          transform: rotate(-15deg);
        }
        75% {
          transform: rotate(15deg);
        }
      }
      
}
.img_box, .info_box
{
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}

.description_box {
  padding: 20px 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}
.info_box {
  margin-left: 20px;
  flex: 1;
}
.description_box {
  margin-top: 20px;
}
.task_tip {
  margin-bottom: 10px;
  padding: 13px 30px;
  border-radius: 6px;
  background: #FFF8DE;
}
.richTxt {
  max-height: 500px;
  overflow: auto;
  img {
    max-width: 100%;
  }
}
.badgeDetail {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
    .badge_cover {
        width: 500px;
        height: 500px;
        border: 1px solid #dedede;
        overflow: hidden;
        text-align: center;
        .img {
            max-width: 100%;
            max-height: 100%;
            margin: auto;
        }
    }

    .chain {
        border-radius: 50%;
    }

    .time_box {
        padding: 0 20px;
        background: #F7F3FD;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    .addr_box {
      height: 70px;
      background: #F7F3FD;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px auto;
      padding: 0 20px;
      border-radius: 4px;
    }
    .info_item {
        height: 70px;
        margin-right: 10px;
        width: 100%;
        background: #F7F3FD;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
    }
    :global {
        .ant-table-thead > tr > th {
            padding: 10px 16px;
        }
        .ant-table-tbody > tr > td, .ant-table tfoot > tr > th {
            padding: 10px;
            border-bottom: none;
        }
    }
}
.pl30_h5 {
    padding-left: 50px;
}
.mr10_h5 {
    margin-right: 10px;
}
.tip_box {
    border: 1px solid #F9E3AA;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    color: #BF924F;
    background: #FDFAF3;
    padding: 24px 10px;
}
.owerInfo {
    // img {
    //     width: 30px;
    //     height: 30px;
    //     border-radius: 50%;
    // }
}
.owners_box {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;

  .owners_item {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    border-right: 1px solid #EBEAEC;
    border-bottom: 1px solid #EBEAEC;
    &:nth-child(-n+4) {
      border-top: 1px solid #EBEAEC;
    }
    &:nth-child(4n+1) {
      border-left: 1px solid #EBEAEC;
    }
  }
}
.owners_pagination {
  text-align: center;
  margin-top: 30px;
}

@media #{$smWidth} {
  .info_box {
    margin-left: 0;
    margin-top: 8px;
  }
  .description_box {
    margin-top: 8px;
    margin-bottom: 20px;
  }
    .badgeDetail {
        .badge_cover {
            width: 100%;
            height: auto;
            margin: 0;
            .img {
                width: 100%;
            }
        }
        .pl30_h5 {
            padding-left: 0;
        }
        .mr10_h5 {
            margin-right: 0;
            margin-bottom: 10px;
        }

        .time_box, .addr_box, .info_item {
            padding: 0 8px;
            height: 62px;
        }
    }
    .owners_box {
      .owners_item {
        width: 50%;
        &:nth-child(-n+4) {
          border-top: none;
        }
        &:nth-child(4n+1) {
          border-left: none;
        }
        &:nth-child(-n+2) {
          border-top: 1px solid #EBEAEC;
        }
        &:nth-child(2n+1) {
          border-left: 1px solid #EBEAEC;
        }
      }
    }
}