@import "assets/css/_variables.scss";

.space_between {
  display: flex;
  justify-content: space-between;
}
.card_list {
  display: flex;
  flex-wrap: wrap;
}

.df_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wp31 {
  width: 34%;
}
.wp23 {
  width: 22%;
}
.table {
  border: 1px solid #000;
  border-radius: 10px;
}
.table_header {
  background: #000;
  color: #fff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.table_item_gray {
  background: #F4F4F4;
}
.des {
  li {
    list-style-type: disc;
    list-style-position: inside;
  }
  .des_content {
    background: #f4f4f4;

  }
}

@media #{$smWidth} {
  .card {
    width: 88%;
    margin: 0 auto 15px auto;
   
  }
  .radio:global(.ant-radio-group) {
    :global(.ant-radio-button-wrapper) {
      padding: 6px 10px;
      margin-right: 10px;
    }
  }  
}
.mr5 {
  margin-right: 5px;
}