@import "assets/css/_variables.scss";

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.financial {
  color: #000;
}

.card_main_t {
  width: 28%;
  margin-bottom: 20px;
}

.card {
  border: 1px solid #f6f6f6;
  cursor: pointer;
  margin-bottom: 15px;
  background: #fff;
  border: 2px solid #000;
  border-radius: 4px;
  box-shadow: 8px 8px 0px 0px black;
  overflow: hidden;
}

.top_img {
  position: relative;
  width: 100%;
  padding-bottom: 115%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  // border-top-left-radius: 4px;
  // border-top-right-radius: 4px;
  // border-radius: 8px;
}

.chainType {
  position: absolute;
  top: 0;
  left: 0;
}

.info_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 5px 10px;
  color: #000;
}

.info_title {
  flex: 1;
  font-weight: 600;
  font-size: 16px;
}

.descrption {
  margin-top: 38px;
  border-radius: 4px;
  background-color: #f4f4f4;
  padding: 24px;

  h2 {
    font-weight: 600;
    font-size: 16px;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
}

@media #{$smWidth} {
  .card_main_t {
    width: 90%;
    margin: 0 auto 10px auto;

  }
}