@import "assets/css/_variables.scss";

.card {
  padding: 20px;
}
.add_border {
  border-top: $hr;
  // border-bottom: $hr;
}
.content_box {
  margin: 10px 60px;
}
.imgBox {
  margin-top: 10px;
  min-height: 100px;
  max-height: 520px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.nftTag {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 8;
  padding: 4px 20px;
  background-color: rgba(0, 0, 0, .6);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
}
.cardImage {
  width: 100%;
}

.bottom_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cell_module {
  display: flex;
  align-items: center;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  width: 60px;
}
.icon {
  // width: 20px;
}

.quoted_box {
  font-size: 12px;
  width: 90%;
  color: rgba(0, 0, 0, 0.4);
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.quoted_hr {
  // background: #f9f9f9;
  height: 1px;
  margin: 10px 0;
}

.card_more_box {
  :global {
    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
    }
  }
}
