@import "./variables";
@import "./default";
@import "./mixin";
@import "./ant-reset";


@include Margins((-10,-20,-2,2, 4, 5, 6, 8, 10, 12, 14, 15, 16, 20, 24, 30, 32, 40, 50, 60), 1px);
@include Paddings((2, 4, 5, 6, 8, 10, 12, 14, 15, 16, 20, 24, 30, 32, 40, 60), 1px);
@include SACSS(font-size, fs, (0, 12, 14, 16, 18, 20, 22, 24, 26, 28, 32, 36, 38, 40, 60), 1px);
@include SACSS(line-height, lh, (12, 16, 18, 20, 24, 28, 32, 36, 48, 80, 84), 1px);
@include SACSS(font-weight, fw, (300, 500, 600, 700));
@include SACSS(border-radius, br, (4, 6, 10), 1px);

.brp50 {
  border-radius: 50%
}

.ell {
  @include Ellipsis;
}

.ells {
  @include Ellipsis-multiple;
}

.ells._2 {
  -webkit-line-clamp: 2;
}

.fwb {
  font-weight: bold;
}

.hide {
  display: none !important;
}
.lh13 {
  line-height: 1.3;
}

.bt_hr {
  border-top: $hr;
}
.br_hr {
  border-right: $hr;
}
.bl_hr {
  border-left: $hr;
}
.bb_hr {
  border-bottom: $hr;
}
.bt_hr {
  border-top: $hr;
}
.coming_soon {
  text-align: center;
  font-size: 16px;
  padding: 20px;
}

.content_text {
  font-size: 16px;
  font-weight: 300;
  color: #000;
  word-break: break-word;
  white-space: pre-wrap;
}

.page_content_h5 {
  max-width: 1100px;
  padding: 20px;
  margin: 20px auto 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}
.page_content_h5_1280 {
  max-width: 1280px;
  padding: 10px 20px;
  margin: 20px auto 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
}
.page_collection_list {
  max-width: 1280px;
  padding: 30px 0;
  margin: auto;
}

.page_content_pl_h5 {
  padding-left: 40px;
}

.page_content_title {
  line-height: 40px;
  font-size: 36px;
  font-weight: 600;
}
.page_content_subtitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.df_h5 {
  display: flex;
}

.df_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.df_center_h5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.df_align_center {
  display: flex;
  align-items: center;
}
.df_align_center_h5 {
  display: flex;
  align-items: center;
}
.df_column_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}
.space-between-h5 {
  display: flex;
  justify-content: space-between;
}

.space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.space-between-center-h5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pos_r {
  position: relative;
}
.c_000 {
  color: #000;
}
.c_f {
  color: #fff;
}
.c_9a {
  color: #9a9a9a;
}
.link_color {
  color: $linkColor;
}
.c_blue {
  color: #1673E1;
}
.c_red {
  color: #E84444;
}
.c_purple {
  color: #6F4293;
}

.bg_f9 {
  background-color: #f9f9f9;
}
.bg_fff {
  background-color: #fff;
}

.bg_f4{
  background-color: #f4f4f4;
}
.bg_hot_1 {
  background: #FF2F13;
}
.bg_hot_2 {
  background: #FF8C22;
}
.bg_hot_3 {
  background: #FFD15A;
}

.btn_multicolour {
  background: $multicolour;
  color: #fff;
  font-weight: 600;
  box-shadow: none;
  border-color: #fff;

  &:hover,
  &:focus,
  &:active {
    background: $multicolour;
    color: #fff;
    font-weight: 600;
    border-color: #fff;
  }
}

.btn_multicolour[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
}
.btn_multicolour_dark {
  background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);;
  color: #fff;
  font-weight: 600;
  box-shadow: none;
  border-color: #fff;

  &:hover,
  &:focus,
  &:active {
    background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);;
    color: #fff;
    font-weight: 600;
    border-color: #fff;
  }
}

.btn_border {
  border: 1px solid #333;
  border-radius: 6px;
  background: #fff;
  &:hover, &:focus {
    border: 1px solid #333;
    border-radius: 6px;
    background: #fff;
    color: #000;
  }
}
.btn_danger {
  border: 1px solid #ec0000;
  border-radius: 6px;
  color: #ec0000;
  &:hover, &:focus {
    border: 1px solid #ec0000;
    border-radius: 6px;
    background: #fff;
    color: #ec0000;
  }
}



.w100 {
  width: 100px;
}

.w120 {
  width: 120px;
}
.w140 {
  width: 140px;
}

.w180 {
  width: 180px;
}

.w200 {
  width: 200px;
}
.w200_h5 {
  width: 200px;
}
.wp24 {
  width: 24% !important;
}
.wp40 {
  width: 40%;
}

.wp50 {
  width: 50%;
}

.wp60 {
  width: 60%;
}
.wp65_h5 {
  width: 65%;
}

.h30 {
  height: 30px;
}

.h40 {
  height: 40px;
}

.h50 {
  height: 50px;
}

.text_hidden_1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.text_hidden_2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.color_text {
  background: $multicolour;
  background-clip: text;
  color: transparent;
}
.countDown {
  span {
      font-weight: 600;
      background: $multicolour;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
  }
}
.page_collection_list {
  .infinite-scroll-component  {
    display: flex;
    flex-wrap: wrap;
  }
}
.txt_nowrap {
  white-space: nowrap;
}
.border_right {
  border-right: 1px solid #ddd;
}

@media #{$smWidth} {
  .page_content_h5, .page_content_h5_1280,.page_collection_list {
    width: calc(100% - 16px);
    padding: 10px 12px;
    margin: 16px 8px 0;
  }
  .page_content_title {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }

  .page_content_pl_h5 {
    padding-left: 0;
  }

  .df_h5 {
    display: flex;
    flex-direction: column;
  }
  .df_center_h5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .df_align_center_h5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .space-between-h5 {
    display: flex;
    flex-direction: column;
  }

  .space-between-center-h5 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .w200_h5 {
    width: 100%;
  }
  .wp65_h5 {
    width: 100%;
  }
}

.aic {
  align-items: center
}

.aife {
  align-items: flex-end
}

.aifs {
  align-items: flex-start
}

.b0 {
  bottom: 0
}

.bct {
  background-color: transparent
}

.bn {
  border: none
}

.br0 {
  border-radius: 0
}

.bsbb {
  box-sizing: border-box
}

.bc_fff {
  background-color: #fff
}

.bc_000 {
  background-color: #000
}

.c_000 {
  color: #000 !important
}

.c_fff {
  color: #fff
}

.cb {
  clear: both
}

.cp {
  cursor: pointer
}

.db {
  display: block
}

.df {
  display: flex
}

.di {
  display: inline
}

.dib {
  display: inline-block
}

.dif {
  display: inline-flex
}

.dn {
  display: none
}

.f1 {
  flex: 1;
  min-width: 0
}
.f2 {
  flex: 2;
  min-width: 0
}
.f3 {
  flex: 3;
  min-width: 0
}
.f4 {
  flex: 4;
  min-width: 0
}
.fa {
  flex: auto
}

.fdc {
  flex-direction: column
}

.fdr {
  flex-direction: row
}

.fi {
  font: inherit
}

.fl {
  float: left
}

.fr {
  float: right
}

.fs0 {
  font-size: 0
}

.fs1 {
  flex-shrink: 1
}

.fsi {
  font-style: italic
}

.fsn {
  font-style: normal
}

.fww {
  flex-wrap: wrap
}

.hp100 {
  height: 100%
}

.jcc {
  justify-content: center
}

.jcfe {
  justify-content: flex-end
}

.jcsa {
  justify-content: space-around
}

.jcsb {
  justify-content: space-between
}

.l0 {
  left: 0
}

.lp100 {
  left: 100%
}

.lp50 {
  left: 50%
}

.lsn {
  list-style: none
}

.m0 {
  margin: 0
}

.mla {
  margin-left: auto
}

.mra {
  margin-right: auto
}

.mta {
  margin-top: auto
}

.mba {
  margin-bottom: auto
}

.o0 {
  opacity: 0
}

.oa {
  overflow: auto;
  -webkit-overflow-scrolling: touch
}

.oh {
  overflow: hidden
}

.p0 {
  padding: 0
}

.pa {
  position: absolute
}

.pen {
  pointer-events: none
}

.pf {
  position: fixed
}

.pr {
  position: relative
}

.ptp100 {
  padding-top: 100%
}

.r0 {
  right: 0
}

.rp100 {
  right: 100%
}

.t0 {
  top: 0
}

.tp100 {
  top: 100%
}

.tp50 {
  top: 50%
}

.mwp100 {
  max-width: 100%
}

.tac {
  text-align: center
}

.taj {
  text-align: justify
}

.tal {
  text-align: left
}

.tar {
  text-align: right
}

.tdn {
  text-decoration: none
}

.tdu {
  text-decoration: underline
}

.usn {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.vh {
  visibility: hidden
}

.wp100 {
  width: 100%
}
.wp80 {
  width: 80%
}

.wsn {
  white-space: nowrap
}

.wwbw {
  word-wrap: break-word;
  word-break: break-all
}