@import "assets/css/_variables.scss";
.blind_box {
  text-align: center;
  width: 600px !important;
  :global(.ant-modal-footer){
    text-align: center;
  }
  .pop_img {
    text-align: center;
    img {
      margin: auto;
    }
  }
}
.totalSplicedModal {
  text-align: center;
  :global{
    .ant-modal-title {
      text-align: center;
    }
    .ant-modal-footer {
      text-align: center;
    }
  }
  :global {
    .ant-table-thead > tr > th {
      line-height: 1.5 !important;
      background-color: #e5e5e5 !important;
      color: #000 !important;
      font-weight: 500;
      &::before {
        display: none;
      }
    }
    .ant-table-tbody > tr > td {
      line-height: 26px !important;
    }
    .rowBgWhite {
      background: #fff !important;
    }
    .rowBgGray {
      background: #f9f9f9 !important;
    }
  }
 
}
.content{
  .main_area {
    flex: 1;
    position: relative;
  }
  .header_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 20px;
    .title {
      font-size: 40px;
      font-weight: 600;
      color: #000;
    }
  }
  .skip {
    padding-left: 5px;
    background: linear-gradient(
  205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  color: #000;
  :global {
    .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
      background-color: #9a9a9a;
      color: #fff;
    }
  }
  
  .df_center {
    display: flex;
    align-items: center;
  }
  .card_main{
    display: flex;
    justify-content: space-between;

    :global {
      .ant-btn-primary {
          background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
      }
      .ant-btn-primary[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
      }
    }
  }
  .card_main_vertical {
    :global {
      .ant-btn-primary {
          background: linear-gradient(205deg, #32C5FF 0%, #B620E0 51.26%, #F7B500 100%);
      }
      .ant-btn-primary[disabled] {
        color: rgba(0, 0, 0, 0.25);
        background: #f5f5f5;
      }
    }
  }
  .card_item {
    width: 100%;
    min-width: 900px;
    padding: 20px;
    margin-bottom: 20px;
    background: #f9f9f9;
  }
  .complete_img {
    position: relative;
    .bg_img {
      width: 280px;
    }
    .bg_img_vertical {
      width: 100%;
    }
    .puzzle_img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .img_gary {
    filter: grayscale(100%);
  }
  .pieces_list {
    display: flex;
    flex-wrap: wrap;
    width: 580px;
  }
  .pieces_item {
    position: relative;
    .pieces_img {
      width: 110px;
    }
    img {
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      background: #fff;
    }
    .img_gary {
      filter: grayscale(100%);
      background: #eaeaea;
    }
    .pieces_count {
      position: absolute;
      top: 3px;
      right: 5px;
      display: flex;
      align-items: center;
      font-size: 12px;
      img {
        width: 12px;
        height: 12px;
        border: 0;
        background: transparent;
        margin-right: 1px;
      }
    }
  }
  
  .item_horizontal {
    width: 100px;
    margin: 0 0 10px 15px;
  }
  .item_vertical {
    width: 160px;
    margin: 0 15px 10px 0;
  }
  .pieces_list_vertical {
    display: flex;
    flex-wrap: wrap;
  }
  
  

}

@media #{$smWidth} {
  .content {
    width: 100%;
    .main_area {
      width: 100%;
    }
    .card_main {
      display: flex;
      flex-direction: column;
    }
    .header_title {
      margin: 20px 0;
      .title {
        font-weight: 600;
        color: #000;
        font-size: 22px;
      }
    }
    .card_item {
      width: 100%;
      min-width: 300px;
      padding: 0px;
      margin-bottom: 20px;
      background: #fff;
    }
    .complete_img {
      .bg_img {
        width: 100%;
      }
      
    }
    .pieces_list {
      width: 100%;

    }
    .item_horizontal {
      width: 60px;
      margin: 0 0 5px 6px;
    }
    .item_vertical {
      width: 75px;
      margin: 0 0 5px 6px;

    }
    .pieces_item {
      .pieces_count {
        right: 3px;
      }
    }
  }
}