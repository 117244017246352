@import "assets/css/_variables.scss";

.collectionManage {
  max-width: 1280px;
  margin: 40px auto;
}
.collection_title {
    font-size: 36px;
    font-weight: 600;
}
.create_btn {
  width: 200px;
  height: 50px;
  background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
  color: #fff;
  font-size: 16px;
}
.main_list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  overflow: hidden;
}
.collection_item {
  width: 246px;
  height: 260px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 16px;
  border: 1px solid #DEDEDE;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  cursor: pointer;
    .item_cover {
      width: 100%;
      height: 148px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      .cover {
        width: 100%;
        // height: 100%;
        height: auto;
      }
      .chainType {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
      }
    }
    .collection_creator {
        position: relative;
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -30px;
        left: 88px;
        border: 4px solid #fff;
        border-radius: 50%;
        z-index: 2;
        .avatar {
            width: 60px;
            height: 60px;
            border: 1px solid #fff;
            border-radius: 50%;
        }

      }
      .name {
       margin-top: -20px;
       text-align: center;
       font-size: 16px;
       font-weight: 600;
      }
      .des {
        margin-bottom: 30px;
        text-align: center;
        font-size: 14px;
        padding: 0 5px;
        color: #1673e1;
      }
}
.collection_item:hover {
  .item_cover {
    height: 70px;
  }
  .des {
    display: none;
  }
  .collection_des {
    width: 100%;
    padding: 0 10px 10px 10px;
    background: #fff;
    height: 112px;
    font-size: 12px;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
    text-align: center;
  }
}
.noCollection_tip {
  padding: 30px;
  background: #fff;
  border: 1px solid #C2ABD7;
  border-radius: 10px;
}  
.createCollection {
  max-width: 1280px;
  margin: 40px auto;

  .formBox {
    padding: 25px 50px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    // box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
    border-radius: 10px;
  }
  .uploadBox {
    width: 524px;
    height: 620px;
    background: #F9F9F9;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    overflow: hidden;
  }
  .upload_common {
    height: 524px;
    overflow: hidden;

    :global {
      .ant-upload.ant-upload-drag {
        border: none;
      }
      .ant-upload.ant-upload-drag .ant-upload {
        padding: 0;
      }
    }
  }
  .aiBox {
    height: 94px;
    padding: 20px 30px;
    background: #EDEDED;
    border-radius: 0px 0px 6px 6px;
  }
  .form_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .level {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    :global {
      .ant-form-item-control {
        width: 460px;
        flex-grow: 0;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #d9d9d9;
        background: #f9f9f9;
      }
    }
  }
}
.border_left {
  border-left: 1px solid #DEDEDE;
}
.collectionDetailPage {
  width: 1280px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;

  .collection_baseInfo {
    position: relative;
    display: flex;
    padding: 30px 40px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
  }
  .collection_logo {
    width: 300px;
    height: 300px;
    overflow: hidden;
  }
  .baseInfo_name {
    font-weight: 600;
    font-size: 34px;
  }
  .baseInfo_desc {
    width: 860px;
    font-size: 14px;
    line-height: 20px;
    word-wrap:break-word;
  }
  .info_list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .w250 {
      width: 250px;
    }
    .w270 {
      width: 270px;
    }
  }
  .info_item {
    // margin-right: 10px;
    padding: 10px 30px;
    background: #F7F3FD;
    border-radius: 4px;
  }
  .func_box {
    position: absolute;
    top: 25px;
    right: 40px;
    display: flex;
    justify-content: flex-end;
  }
  .manage_disable {
    opacity: 0.5;
  }
  .publish_btn {
    width: 140px;
    height: 40px;
    margin-right: 6px;
    background: linear-gradient(90deg, #1F141C 0%, #440C4E 100%);
    color: #fff;
    font-size: 16px;
  }
  .collection_setting {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 40px;
    background: #F7F3FD;
    border-radius: 6px;
  }
  .badgeManage {
    margin-top: 20px;
    padding: 30px 40px;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 24px rgba(77, 31, 114, 0.15);
  }
  .nft_list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .add_card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 263px;
      margin: 8px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      cursor: pointer;
    }

    .badge_item {
      width: 226px;
      margin-right: 40px;
      margin-bottom: 10px;
    }
    .badge_box {
      position: relative;
      width: 225px;
      height: 225px;
      border: 1px solid #DEDEDE;
      border-radius: 6px;
      overflow: hidden;
    }
    .badge_box:hover{
      .badge_func {
        display: flex;
      }
    }
    .badge_img {
      width: 100%;
    }
    .badge_func {
      position: absolute;
      top: 20px;
      left: 24px;
      display: none;
      align-items: center;
      width: 180px;
      height: 40px;
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid #FFFFFF;
      border-radius: 20px;
    }
    .line_left {
      border-left: 1px solid #DEDEDE;
    }
    .add_box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 225px;
      height: 225px;
      font-size: 48px;
      color: #DEDEDE;
      background: #FFFFFF;
      border: 1px solid #DEDEDE;
      border-radius: 6px;
    }
  }
  .modal_btns {
    display: flex;
    justify-content: space-between;
    height: 40px;
    margin-top: 10px;
    padding: 6px 0px;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    .btns_right {
      border-left: 1px solid #DEDEDE;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.drafts {
  max-width: 1280px;
  margin: 40px auto 10px auto;
  padding: 20px 30px;
  background: #F9F9F9;
  border-radius: 8px;
}

@media #{$smWidth} {

}