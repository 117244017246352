@import "assets/css/_variables.scss";
$rightWidthL: 320px;
$rightWidthS: 300px;

.title {
  font-size: 26px;
  line-height: 36px;
  font-weight: 600;
  margin: 20px 0 15px;
}
@keyframes followRefresh
{
  from{-webkit-transform: rotate(0deg);}
  to{-webkit-transform: rotate(360deg);}
}
.refreshIcon {
  animation: followRefresh 1s linear;
  -webkit-animation: followRefresh 1s linear;
  animation-iteration-count:3;
  -webkit-animation-iteration-count:3;
}
.recommend_box {
  width: $rightWidthL;
}
.recommend_box_fixed {
  position: fixed;
  width: $rightWidthL;
  padding: 0 20px;
  top: 70px;
  bottom: 30px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.recommend_img {
  width: 100%;
  border-radius: 6px;
  margin-bottom: 10px;
}
.more_box {
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: right;
  border-bottom: $hr;
  &:last-child {
    border-bottom: none;
  }
}
.more_btn {
  display: inline-flex;
  align-items: center;
}
.arrows {
  margin-left: 7px;
  height: 10px;
}
.user_name {
  font-size: 14px;
  font-weight: 600;
  width: 110px;
  margin-left: 12px;
}

@media (max-width: 1320px) {
  .recommend_box {
    width: $rightWidthS;
  }
  .recommend_box_fixed {
    width: $rightWidthS;
  }
  .user_name {
    width: 100px;
  }
}
@media (max-width: 980px) {
  .recommend_box {
    display: none;
  }
  .more_box {
    padding-bottom: 0px;
    text-align: center;
  }
}
