@import "assets/css/_variables.scss";

.token2049 {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;

  .token_main {
    width: 340px;
    margin: auto;
    padding-top: 10px;
    text-align: center;
  }
  .line_right {
    padding-right: 30px;
    border-right: 1px solid #AE81AA;
  }
  .text1 {
    font-size: 12px;
    color: #D0AFD2;
  }
  .text2 {
    margin-top: 10px;
    color: #F0B5FF;
  }
  .chain {
    border-radius: 50%;

  }
  .claimBtn_border {
    border: 1px solid #EF7CD6;

  }
  .more_btn {
    width: 100%;
    height: 50px;
    border: 1px solid #EF7CD6;
    font-size: 16px;
    border-radius: 6px;
    background: transparent;
    color: #F0B5FF;
  }

  .about_btn {
    display: block;
    height: 50px;
    line-height: 48px;
    border: 1px solid #8A62CD;
    font-size: 16px;
    border-radius: 6px;
    background: rgba(246, 143, 255, 0.15);
    color: #fff;
  }
}
:global{
  .custom-class {
    font-size: 16px;
    .ant-message-custom-content {
      background: #2E114B;
      border: 1px solid #DD7BFF;
    }
  }
}

@media #{$smWidth} {
  
}